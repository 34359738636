import React from 'react';

import { SVGIconComponent } from '../types';

export const add: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="m19 13h-6v6h-2v-6h-6v-2h6v-6h2v6h6v2z" />
  </svg>
);
