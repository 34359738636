import React from 'react';

import { SVGIconComponent } from '../types';

export const mobileNavHomeTH: SVGIconComponent = ({ title = '', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0  0 24 24" {...props}>
    <title>{title}</title>
    <path d="M12 0c.946 2.714 2.37 3.634 4.27 2.76a194.698 194.698 0 0 1-.422 7.52l2.142-2.707c.633 2.234 2.274 2.695 4.925 1.383-.695 1.885-1.585 3.71 1.085 5.178a742.943 742.943 0 0 0-6.76 5.407l1.892 1.989h-3.475v1.481c-1.687-.268-2.906.062-3.657.989-.751-.927-1.97-1.257-3.657-.989V21.53H4.868l1.893-1.989A742.943 742.943 0 0 0 0 14.134c2.67-1.467 1.78-3.293 1.085-5.178 2.65 1.312 4.292.85 4.925-1.383l2.142 2.706A194.698 194.698 0 0 1 7.73 2.76C9.63 3.634 11.054 2.714 12 0z" />
  </svg>
);
