import styled, { css } from 'styled-components';

const linkStyles = css`
  a {
    color: ${(p) => p.theme.token('text-link-default')};
    text-decoration: underline;
    &:hover {
      color: ${(p) => p.theme.token('text-link-hover')};
    }
  }
`;

const Wrapper = styled.div<{ $disabled?: boolean }>`
  margin: 0 0 0 8px;
  cursor: ${(p) => (p.$disabled ? 'not-allowed' : 'pointer')};
`;

const LabelHeader = styled.p<{ $disabled?: boolean }>`
  font: ${(p) => p.theme.token('text-style-form-checkbox-header')};
  color: ${(p) => (p.$disabled ? p.theme.token('text-disabled') : p.theme.token('text-default'))};
  margin: 0 0 4px 0;
  ${linkStyles}
`;

const LabelText = styled.label<{ $disabled?: boolean }>`
  font: ${(p) => p.theme.token('text-style-form-checkbox')};
  color: ${(p) => (p.$disabled ? p.theme.token('text-disabled') : p.theme.token('text-default'))};
  margin: 0;
  ${linkStyles}
`;

export { LabelHeader, LabelText, Wrapper };
