import React from 'react';

import { SVGIconComponent } from '../types';

export const driveThru: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 22 15" {...props}>
    <title>{title}</title>
    <path d="M20 6.2L18.2.9c-.1-.4-.5-.7-.9-.7H8.7c-.4 0-.8.3-.9.7L6 6.2c-1.1 0-2 .9-2 2V14.3c0 .3.2.5.5.5h1.6c.3 0 .5-.2.5-.5v-1.7h12.8v1.7c0 .3.2.5.5.5h1.6c.3 0 .5-.2.5-.5V8.2c0-1.1-.9-2-2-2zM8.9 1.7c.1-.2.3-.3.5-.3h7.5c.2 0 .4.1.5.3l1.5 4.5H7.4l1.5-4.5zm-2.3 9.1c-.7 0-1.2-.6-1.2-1.2S6 8.4 6.6 8.4s1.2.6 1.2 1.2-.5 1.2-1.2 1.2zm12.8 0c-.7 0-1.2-.6-1.2-1.2s.6-1.2 1.2-1.2c.7 0 1.2.6 1.2 1.2s-.5 1.2-1.2 1.2zM3.4 4.1c-.1-.3-.4-.4-.6-.4l-2.4.6c-.2.1-.4.3-.4.5v.9c0 .3.2.5.5.5h.2v8.2c0 .3.2.5.5.5h.3c.3 0 .5-.2.5-.5V6.2h.9c.3 0 .5-.2.5-.5V4.2v-.1z" />
  </svg>
);
