import React from 'react';

import { SVGIconComponent } from '../types';

export const kidsMeal: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 65 52" {...props}>
    <title>{title}</title>
    <path d="M33.9 7.5c.1-.1.3-.2.5-.2.6-.1 1.2.3 1.3.9v.2c0 .5-.4.9-.9 1-.6.1-1.2-.3-1.3-.9-.1-.4.1-.8.4-1m4.5 6.3c1.3-1.5.3-3-.5-3.9l2.2-2.4-5.7-2.2c-.2-1.4-1-3.7-3.2-3.6-.7 0-1.3.2-1.7.5-.1-1-.6-1.8-1.5-2.1-1.3-.5-2.8.3-3.4 1.7 0 0 0 .1-.1.1-.1.3-.2.6-.2.8 0 .5 0 .9.2 1.3.3.9 1 1.8 2.5 2.3.3.1.6.2.9.2h1.5l-4.6 14.7c-.5 1.1-1.8 3.9-3.6 5.4-3.3 2.9-5.5.3-5.5.3-3.7-4.2 6.7-11.1.6-17.9-4-4.5-11.4-2-16.3 1.7L3 17h.1c3-2.7 5.3 0 5.3 0 3.5 3.9-3.6 9.1-3 15.5V33.3c.7 3.8 4.8 12.2 14.9 13.1l.5 5.5h3.4l.5-5.6c.5-.1 1.1-.2 1.6-.3 10.2-2 12.6-9 12.6-15.4 0-2-1.9-13-2.5-16.3.8.2 1.4.1 2-.5" />
    <path d="M61.4 22.6c.4-.1.7.2.8.7v.1c0 .4-.2.7-.5.7-.4.1-.7-.2-.8-.7 0-.3.1-.6.3-.7 0 0 .1-.1.2-.1zm2.2 1.7l1.4-1.5-3.6-1.4c-.1-.9-.6-2.3-2-2.3-.4 0-.8.1-1 .3 0-.6-.4-1.1-.9-1.4-.8-.3-1.7.2-2.1 1.1v.1c-.1.2-.1.3-.1.5 0 .3 0 .6.1.8.2.6.6 1.2 1.5 1.5.2.1.4.1.6.1h.9l-2.9 9.3c-.3.7-1.1 2.4-2.2 3.4-2 1.8-3.4.2-3.4.2-2.3-2.7 4.2-7 .4-11.3-2.7-2.8-7.2-1.2-10.3 1.2l1.9 4c1.9-1.7 3.3 0 3.3 0 2.2 2.4-2.3 5.8-1.9 9.8v.6c.5 2.4 3 7.8 9.3 8.3L53 51h2.1l.3-3.6c.3 0 .7-.1 1-.2 6.4-1.2 7.9-5.7 7.9-9.8 0-1.3-1.2-8.2-1.6-10.3.4.1.8 0 1.1-.4 1-.9.4-1.9-.2-2.4z" />
  </svg>
);
