import React from 'react';

import { SVGIconComponent } from '../types';

export const coffeeCupFilled: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 14 21" {...props}>
    <title>{title}</title>
    <path d="M1.51 6h10.98a.5.5 0 0 1 .496.568l-1.912 14a.5.5 0 0 1-.496.432H3.422a.5.5 0 0 1-.496-.432l-1.912-14A.5.5 0 0 1 1.509 6zM.5 3h13a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 3zM1 2l1-2h10l1 2" />
  </svg>
);
