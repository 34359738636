import React from 'react';

import { SVGIconComponent } from '../types';

export const visa: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 32 20" {...props}>
    <title>{title}</title>
    <g id="icon-/-card-/-visa" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        id="Rectangle"
        fill="#FFFFFF"
        d="M2,0h28c1.1,0,2,0.9,2,2v16c0,1.1-0.9,2-2,2H2c-1.1,0-2-0.9-2-2V2C0,0.9,0.9,0,2,0z"
      />
    </g>
    <path
      fill="#1434CB"
      d="M13.09,6.23l-3.17,7.56H7.85L6.29,7.76C6.19,7.38,6.11,7.25,5.82,7.09C5.35,6.84,4.58,6.6,3.89,6.45l0.05-0.22
      h3.33c0.42,0,0.81,0.28,0.9,0.77L9,11.38l2.04-5.15H13.09z M21.19,11.32c0.01-2-2.76-2.11-2.74-3c0.01-0.27,0.26-0.56,0.83-0.63
      c0.28-0.04,1.05-0.06,1.93,0.34l0.34-1.6c-0.47-0.17-1.08-0.34-1.83-0.34c-1.93,0-3.29,1.03-3.31,2.5c-0.01,1.09,0.97,1.7,1.71,2.06
      c0.76,0.37,1.02,0.61,1.01,0.94c-0.01,0.51-0.61,0.73-1.17,0.74c-0.98,0.02-1.55-0.27-2.01-0.48l-0.35,1.66
      c0.46,0.21,1.3,0.39,2.18,0.4C19.84,13.91,21.18,12.89,21.19,11.32 M26.3,13.79h1.81l-1.58-7.56h-1.67c-0.38,0-0.69,0.22-0.83,0.55
      l-2.94,7.01h2.05l0.41-1.13h2.51L26.3,13.79z M24.11,11.11l1.03-2.84l0.59,2.84H24.11z M15.88,6.23l-1.62,7.56h-1.96l1.62-7.56
      H15.88z"
    />
  </svg>
);
