import React from 'react';

import { SVGIconComponent } from '../types';

export const fastFood: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="m18.06 22.99h1.66c0.84 0 1.53-0.64 1.63-1.46l1.65-16.48h-5v-4.05h-1.97v4.05h-4.97l0.3 2.34c1.71 0.47 3.31 1.32 4.27 2.26 1.44 1.42 2.43 2.89 2.43 5.29v8.05zm-17.06-1v-0.99h15.03v0.99c0 0.55-0.45 1-1.01 1h-13.01c-0.56 0-1.01-0.45-1.01-1zm15.03-7c0-8-15.03-8-15.03 0h15.03zm-15.01 2.01h15v2h-15v-2z" />
  </svg>
);
