import * as React from 'react';
import { FC } from 'react';

import { useTheme } from 'styled-components';

import { useIdCounter } from '../../hooks';
import { Icon } from '../icon';
import { InputMessage } from '../input-message';
import { Label } from '../label';

import { Check, HiddenInput, IconContainer, LabelWrapper } from './checkbox.styled';
import { ICheckboxProps } from './types';

export const Checkbox: FC<ICheckboxProps> = ({
  'aria-label': ariaLabel,
  'data-testid': dataTestId,
  checked,
  className,
  disabled,
  disableScreenReaderAlert,
  errorMessage = '',
  hintMessage = '',
  label,
  labelHeader = '',
  name,
  onChange,
  required,
}) => {
  const { token } = useTheme();
  const inputID = useIdCounter('checkbox');
  const messageID = useIdCounter('message');
  const labelID = useIdCounter('label');
  const message = errorMessage || hintMessage;

  return (
    <>
      <HiddenInput
        aria-label={ariaLabel}
        aria-describedby={messageID}
        type="checkbox"
        id={inputID}
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        data-testid={dataTestId}
        required={required}
      />

      <LabelWrapper
        className={className}
        htmlFor={inputID}
        {...(dataTestId && { 'data-testid': `${dataTestId}_label_wrapper` })}
        $disabled={disabled}
      >
        <Check
          data-testid="check-component"
          checked={checked}
          $hasError={!!errorMessage}
          $disabled={disabled}
        >
          {checked && (
            <IconContainer>
              <Icon
                icon="check"
                color="icon-form-checkbox"
                width={token('width-checkbox-radio')}
                aria-hidden
              />
            </IconContainer>
          )}
        </Check>
        <Label
          id={labelID}
          label={label}
          labelHeader={labelHeader}
          disabled={disabled}
          {...(dataTestId && { 'data-testid': `${dataTestId}_label` })}
        />
      </LabelWrapper>

      {message && (
        <InputMessage
          id={messageID}
          disableScreenReaderAlert={disableScreenReaderAlert}
          data-testid={`${dataTestId}_message`}
          hasError={!!errorMessage}
          disabled={disabled}
        >
          {message}
        </InputMessage>
      )}
    </>
  );
};

export default Checkbox;
