import React, { FC } from 'react';

import styled, { DefaultTheme } from 'styled-components';

import { getThemeColor } from '../../utils/get-theme-color';

import { OutlineButton } from './Button.styles';
import { ITextOnlyButtonProps } from './types';

const getColor = ({
  disabled,
  color,
  theme,
}: {
  disabled?: ITextOnlyButtonProps['disabled'];
  color?: ITextOnlyButtonProps['color'];
  theme: DefaultTheme;
}) => {
  if (disabled) {
    return theme.color?.buttonsDisabledLabel;
  }
  return (color && getThemeColor(theme, color)) || 'black';
};

export const BaseTextOnlyButton = styled(OutlineButton)<ITextOnlyButtonProps>`
  border: 0;
  color: ${getColor};
  background-color: transparent;
  border-radius: 0;
  padding: 0;

  div {
    > svg {
      transition: fill 0.125s ease;
      fill: ${getColor};
      stroke: ${getColor};
    }
  }

  &:hover {
    color: ${({ theme, disabled }) =>
      disabled ? theme.color?.buttonsDisabledLabel : theme.color?.primary};
    background-color: transparent;
    div {
      > svg {
        transition: fill 0.125s ease;
        fill: ${({ theme, disabled }) =>
          disabled ? theme.color?.buttonsDisabledLabel : theme.color?.primary};
        stroke: ${({ theme, disabled }) =>
          disabled ? theme.color?.buttonsDisabledLabel : theme.color?.primary};
      }
    }
  }

  &:active {
    transform: ${(p) => (p.disabled ? 'none' : 'scale(0.95)')};
    color: ${({ theme, disabled }) =>
      disabled ? theme.color?.buttonsDisabledLabel : theme.color?.primary};
    background-color: transparent;
    div {
      > svg {
        transition: fill 0.125s ease;
        fill: ${({ theme, disabled }) =>
          disabled ? theme.color?.buttonsDisabledLabel : theme.color?.primary};
        stroke: ${({ theme, disabled }) =>
          disabled ? theme.color?.buttonsDisabledLabel : theme.color?.primary};
      }
    }
  }

  &[data-active],
  &:focus {
    box-shadow: none;
    border-bottom: 0.1rem solid ${({ theme }) => theme.color?.primary};
    color: ${({ theme }) => theme.color?.primary};
    background-color: transparent;
    div {
      > svg {
        transition: fill 0.125s ease;
        fill: ${({ theme }) => theme.color?.primary};
        stroke: ${({ theme }) => theme.color?.primary};
      }
    }
  }
`;

export const TextOnlyButton: FC<ITextOnlyButtonProps> = ({
  color = 'black',
  children,
  transformText,
  ...rest
}) => (
  <BaseTextOnlyButton color={color} transformText={transformText} {...rest}>
    {children}
  </BaseTextOnlyButton>
);
