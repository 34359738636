import React, { FC } from 'react';

import styled, { DefaultTheme } from 'styled-components';

import { getNonTransparentColor } from '../../utils/get-non-transparent-color';
import { getThemeColor } from '../../utils/get-theme-color';

import { OutlineButton } from './Button.styles';
import { IBaseInverseButtonProps, IInverseButtonProps } from './types';

const getColor = ({
  disabled,
  color,
  theme,
}: {
  disabled?: IBaseInverseButtonProps['disabled'];
  color: IBaseInverseButtonProps['color'];
  theme: DefaultTheme;
}) => {
  if (disabled) {
    return theme.color?.buttonsDisabledLabel;
  }
  return getThemeColor(theme, color, color);
};

const getBackgroundColor = ({
  transparent,
  disabled,
  color,
  theme,
}: {
  transparent?: IBaseInverseButtonProps['transparent'];
  disabled?: IBaseInverseButtonProps['disabled'];
  color: IBaseInverseButtonProps['color'];
  theme: DefaultTheme;
}): string => {
  if (disabled) {
    if (transparent) {
      return 'transparent';
    }
    return theme.color?.white;
  }
  return getThemeColor(theme, color, color);
};

const BaseInverseButton = styled(OutlineButton)<IBaseInverseButtonProps>`
  border: 0;
  color: ${getColor};

  div {
    > svg {
      transition: fill 0.125s ease;
      fill: ${getColor};
      stroke: ${getColor};
    }
  }

  &:hover {
    background-color: ${(p) => {
      if (!p.disabled) {
        return getNonTransparentColor({
          hexOrRGB: getBackgroundColor({
            transparent: p.transparent,
            disabled: p.disabled,
            color: p.color,
            theme: p.theme,
          }),
          hexWhite: p.theme.color?.white || '#ffffff',
          opacity: 0.15,
        });
      }
      return getBackgroundColor;
    }};
  }

  &:active {
    background-color: ${(p) => {
      if (p.color === 'primary') {
        return p.theme.color?.buttonsSecondaryActive;
      }
      return getBackgroundColor;
    }};
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem
      ${(p) => {
        if (!p.disabled) {
          return getNonTransparentColor({
            hexOrRGB: getBackgroundColor({
              transparent: p.transparent,
              disabled: p.disabled,
              color: p.color,
              theme: p.theme,
            }),
            hexWhite: p.theme.color?.white || '#ffffff',
            opacity: 0.25,
          });
        }
        return getBackgroundColor;
      }};
  }
`;

export const InverseButton: FC<IInverseButtonProps> = ({
  color = 'text-button-secondary',
  children,
  ...rest
}) => (
  <BaseInverseButton color={color} {...rest}>
    {children}
  </BaseInverseButton>
);
