import React from 'react';

import { SVGIconComponent } from '../types';

export const select: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="m7.41 8.59 4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6 1.41-1.41z" />
  </svg>
);
