import React from 'react';

import { SVGIconComponent } from '../types';

export const thRewards: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="m22.924 13.331c-0.6769-0.2257-2.5628-1.1444-0.9026-4.3036 0.0322-0.04835 0.0161-0.11283-0.0161-0.14506-0.0322-0.03224-0.0967-0.04836-0.1451-0.01612-3.1592 1.644-4.0779-0.24178-4.3036-0.90263-0.0322-0.09671-0.145-0.11283-0.2095-0.03224l-1.8214 2.4016 0.4674-6.7374c0.0162-0.09671-0.0967-0.16119-0.1773-0.11283-0.6286 0.32237-2.6273 0.99934-3.6911-2.4016-0.0161-0.06447-0.0644-0.08059-0.1128-0.08059s-0.0967 0.03224-0.1128 0.08059c-1.0638 3.401-3.0625 2.724-3.6911 2.4016-0.08059-0.04836-0.1773 0.01612-0.1773 0.11283l0.46743 6.7374-1.8375-2.4177c-0.06448-0.08059-0.1773-0.04835-0.20954 0.03224-0.22566 0.67697-1.1444 2.5628-4.3036 0.90263-0.04835-0.03224-0.11283-0.01612-0.14506 0.01612-0.03224 0.03224-0.04836 0.09671-0.01612 0.14506 1.6441 3.1592-0.24178 4.078-0.90263 4.3036-0.096713 0.0323-0.11283 0.1451-0.03224 0.2096l6.1895 4.7065-1.6763 1.6602c-0.06448 0.0645-0.01612 0.1935 0.08059 0.1935h2.9658v1.2733c0 0.0322 0.04836 0.1128 0.12895 0.0967 0.62861-0.0967 2.0792-0.3385 3.1592 0.7737 0.0161 0.0161 0.0483 0.0322 0.0967 0.0322 0.0483 0 0.0806-0.0161 0.0967-0.0322 1.0799-1.1122 2.5306-0.8704 3.1592-0.7737 0.0806 0.0161 0.1289-0.0645 0.1289-0.0967v-1.2733h2.9658c0.0967 0 0.1451-0.129 0.0806-0.1935l-1.6763-1.6602 6.1895-4.7065c0.0967-0.0323 0.0644-0.1612-0.0162-0.1934z" />
  </svg>
);
