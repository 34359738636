import * as React from 'react';

import { SVGIconComponent } from '../types';

export const requestInfo: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="M16.5081 20.622L19.4295 17.6934V20.1715H20.1785V16.4168H16.4332V17.1677H18.9051L15.9838 20.0964L16.5081 20.622ZM18.3059 23C17.0075 23 15.9005 22.5369 14.985 21.6108C14.0695 20.6846 13.6117 19.5874 13.6117 18.3191C13.6117 17.0175 14.0695 15.9036 14.985 14.9775C15.9005 14.0513 17.0075 13.5882 18.3059 13.5882C19.5876 13.5882 20.6904 14.0513 21.6142 14.9775C22.5381 15.9036 23 17.0175 23 18.3191C23 19.5874 22.5381 20.6846 21.6142 21.6108C20.6904 22.5369 19.5876 23 18.3059 23ZM6.99501 8.50688H16.9825V7.00501H6.99501V8.50688ZM12.7378 21.0225H4.49813C4.08198 21.0225 3.72826 20.8765 3.43695 20.5845C3.14565 20.2924 3 19.9378 3 19.5207V4.50188C3 4.08469 3.14565 3.73008 3.43695 3.43805C3.72826 3.14602 4.08198 3 4.49813 3H19.4794C19.8955 3 20.2493 3.14602 20.5406 3.43805C20.8319 3.73008 20.9775 4.08469 20.9775 4.50188V12.7372C20.5614 12.5202 20.1286 12.3617 19.6792 12.2616C19.2297 12.1615 18.772 12.1114 18.3059 12.1114C18.0728 12.1114 17.8481 12.1239 17.6317 12.1489C17.4153 12.174 17.1989 12.2115 16.9825 12.2616V11.2603H6.99501V12.7622H15.5843C14.985 13.0626 14.4482 13.4506 13.9738 13.9262C13.4994 14.4018 13.1124 14.9316 12.8127 15.5156H6.99501V17.0175H12.2634C12.2135 17.2345 12.176 17.4514 12.1511 17.6683C12.1261 17.8853 12.1136 18.1106 12.1136 18.3442C12.1136 18.8281 12.1635 19.287 12.2634 19.7209C12.3633 20.1548 12.5214 20.5887 12.7378 21.0225Z" />
  </svg>
);
