import React from 'react';

import { SVGIconComponent } from '../types';

export const editWithEraser: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 13 13" {...props}>
    <title>{title}</title>
    <path d="M0 10.14V12.1667C0 12.3534 0.146667 12.5 0.333333 12.5H2.36C2.44667 12.5 2.53333 12.4667 2.59333 12.4L9.87333 5.12671L7.37333 2.62671L0.1 9.90004C0.0333334 9.96671 0 10.0467 0 10.14ZM11.8067 3.19337C12.0667 2.93337 12.0667 2.51337 11.8067 2.25337L10.2467 0.693372C9.98667 0.433372 9.56667 0.433372 9.30667 0.693372L8.08667 1.91337L10.5867 4.41337L11.8067 3.19337Z" />
  </svg>
);
