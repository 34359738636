import React, { forwardRef } from 'react';

import { usePersistentlyTrueBool } from '../../hooks/use-persistently-true-bool';
import { Icon } from '../icon';
import { LoadingAnimation } from '../loading-animation';

import {
  IconOnlyButtonStyle,
  LoadingWrapper,
  PrimaryButtonStyle,
  SecondaryButtonStyle,
  TertiaryButtonStyle,
  TextWrapper,
} from './button.styled';
import {
  AllButtonProps,
  IconOnlyButtonFn,
  IconOnlyButtonProps,
  SecondaryButtonProps,
} from './types';

type ButtonVariants = 'primary' | 'secondary' | 'tertiary' | 'iconOnly';

const LOADING_SHOW_DELAY_MS = 300;

export const TheEveryButton = forwardRef<
  HTMLButtonElement,
  AllButtonProps & {
    // The `variant` prop, only used internally within the component library,
    // allows us to use TheEveryButton with a variety of different appearances,
    // while still sharing the same core logic
    variant: ButtonVariants;

    // The following color customization options aren't visible on the public
    // API of the components, but are possible to squeeze in using type
    // workarounds so our internal components that require button customization
    // (the `Card`, for example) can provide that functionality
    textColor?: string;
    bgColor?: string;
  }
>(
  (
    {
      children,
      disabled,
      type,
      onClick,
      size,
      icon: iconProp,
      showNavigationIcon,
      fullWidth,
      as,
      to,
      href,
      title,
      variant,
      reversed,
      loading: actuallyLoading,
      loadingMessage,
      textColor,
      bgColor,
      'data-testid': testId,
      'aria-label': ariaLabel,
      'aria-describedby': ariaDescribedBy,
    },
    forwardedRef,
  ) => {
    // Only display the loading indicator after the button has been loading for
    // longer than a fixed timeout. Reduces occurences of loading UI flashing on
    // and off on fast connections.
    const loadingShown = usePersistentlyTrueBool(!!actuallyLoading, LOADING_SHOW_DELAY_MS);

    const Variant = (
      {
        primary: PrimaryButtonStyle,
        secondary: SecondaryButtonStyle,
        tertiary: TertiaryButtonStyle,
        iconOnly: IconOnlyButtonStyle,
      } as const
    )[variant];

    const asProps = (() => {
      if (!as || as === 'button') {
        return {
          as: 'button',
          disabled,
          type,
        } as const;
      }

      if (as === 'a') {
        return { as: 'a', href } as const;
      }

      return { as, to };
    })() as { as: 'button'; type: typeof type; disabled?: boolean } | { as: 'a'; href: string };
    // ^^^ here we cheat the type a bit, omitting the following possibility:
    // | { as: RouterLinkType; to: any };
    // to avoid a type error seeming to originate from styled-components
    // `as`-typing magic.

    let icon = null;
    if (iconProp && (size === 'small' || variant === 'iconOnly')) {
      if (typeof iconProp === 'string') {
        icon = (
          <Icon
            icon={iconProp}
            color="icon-default"
            aria-hidden
            data-testid={testId ? `${testId}-icon` : undefined}
          />
        );
      } else if (iconProp.element) {
        icon = iconProp.element;
      }
    }

    return (
      <Variant
        {...asProps}
        onClick={actuallyLoading ? undefined : (onClick as any)}
        ref={forwardedRef}
        $fullWidth={fullWidth}
        // We pass the data-full-width attribute as a workaround to a
        // styled-components bug. See the component styles consuming this data
        // attribute for more info.
        data-full-width={fullWidth || undefined}
        $hasEndIcon={showNavigationIcon}
        $hasStartIcon={!!icon}
        $reversed={reversed}
        $loading={loadingShown}
        $size={size}
        {...(variant === 'primary' && {
          $textColor: textColor,
          $bgColor: bgColor,
        })}
        data-testid={testId}
        title={title}
        aria-label={ariaLabel}
        aria-describedby={ariaDescribedBy}
      >
        {icon}

        {children && <TextWrapper aria-hidden={loadingShown || undefined}>{children}</TextWrapper>}

        {showNavigationIcon &&
          variant === 'tertiary' &&
          size === 'small' &&
          asProps.as !== 'button' && (
            <Icon
              icon="next"
              color="icon-default"
              aria-hidden
              data-testid={testId ? `${testId}-icon-nav` : undefined}
            />
          )}

        {loadingShown && (
          <LoadingWrapper>
            <LoadingAnimation loadingMessage={loadingMessage || 'Loading'} />
          </LoadingWrapper>
        )}
      </Variant>
    );
  },
);

export const makeIconOnlyButton = (
  initialProps: Pick<SecondaryButtonProps, 'icon' | 'size'>,
): IconOnlyButtonFn =>
  React.forwardRef<HTMLButtonElement, IconOnlyButtonProps>((props, forwardedRef) => (
    <TheEveryButton {...initialProps} {...props} variant="iconOnly" ref={forwardedRef} />
  ));
