import React from 'react';

import { SVGIconComponent } from '../types';

export const telephone: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="m6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c0.27-0.27 0.67-0.36 1.02-0.24 1.12 0.37 2.33 0.57 3.57 0.57 0.55 0 1 0.45 1 1v3.49c0 0.55-0.45 1-1 1-9.39 0-17-7.61-17-17 0-0.55 0.45-1 1-1h3.5c0.55 0 1 0.45 1 1 0 1.25 0.2 2.45 0.57 3.57 0.11 0.35 0.03 0.74-0.25 1.02l-2.2 2.2z" />
  </svg>
);
