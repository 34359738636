import React from 'react';

import { IconWrapper } from './Icon.styles';
import { BaseIconProps, IconProps, NonAccessibleIconProps } from './types';
import { useThemeIcon } from './useThemeIcon';

export function Icon(props: IconProps): React.ReactElement | null;

/** @deprecated to make the icon screen-reader-friendly, specify either `aria-hidden` or give it a non-empty `title` */
export function Icon(props: NonAccessibleIconProps): React.ReactElement | null;

export function Icon({
  color,
  strokeColor,
  hoverColor,
  strokeWidth,
  width,
  height,
  'data-testid': testId,
  title = '',
  'aria-hidden': ariaHidden,
  icon,
}: BaseIconProps) {
  const ThemeIcon = useThemeIcon(icon);

  return ThemeIcon ? (
    <IconWrapper
      color={color}
      hoverColor={hoverColor}
      strokeColor={strokeColor}
      strokeWidth={strokeWidth}
      width={width}
      height={height}
      data-testid={testId}
      aria-hidden={ariaHidden}
    >
      <ThemeIcon title={title} focusable="false" />
    </IconWrapper>
  ) : null;
}
