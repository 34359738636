import React from 'react';

import { SVGIconComponent } from '../types';

export const sandwiches: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 67 46" {...props}>
    <title>{title}</title>
    <path
      d="M6.303 37.327V34h54v3.327c0 4.752-3.824 8.673-8.459 8.673H14.762c-4.635 0-8.459-3.92-8.459-8.673zM3.678 19h59.25c1.875 0 3.375 2.4 3.375 4.985v2.03c0 2.77-1.5 4.985-3.375 4.985H3.678C1.803 31 .303 28.785.303 26.015v-2.03c0-2.77 1.5-4.985 3.375-4.985zM42.921 0H23.685C14.067 0 6.303 6.593 6.303 13.89V16h54v-2.813C60.303 5.89 52.54 0 42.921 0z"
      fillRule="evenodd"
    />
  </svg>
);
