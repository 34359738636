import * as React from 'react';

import { SVGIconComponent } from '../types';

export const payback: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="M12.39 10.9C10.12 10.31 9.38997 9.7 9.38997 8.75C9.38997 7.66 10.4 6.9 12.09 6.9C13.87 6.9 14.53 7.75 14.59 9H16.8C16.73 7.28 15.68 5.7 13.59 5.19V3H10.59V5.16C8.64997 5.58 7.08997 6.84 7.08997 8.77C7.08997 11.08 8.99997 12.23 11.79 12.9C14.29 13.5 14.79 14.38 14.79 15.31C14.79 16 14.3 17.1 12.09 17.1C10.03 17.1 9.21997 16.18 9.10997 15H6.90997C7.02997 17.19 8.66997 18.42 10.59 18.83V21H13.59V18.85C15.54 18.48 17.09 17.35 17.09 15.3C17.09 12.46 14.66 11.49 12.39 10.9Z" />
  </svg>
);
