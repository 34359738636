import React from 'react';

import { SVGIconComponent } from '../types';

export const timsText: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 120 80" enableBackground="new 0 0 120 80" xmlSpace="preserve" {...props}>
    <title>{title}</title>
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={`
              M42.4,25.5c-0.2,0.1-0.5,0.1-0.7,0.2c-2.3,0.9-3.5,3.5-2.8,5.9c0.6,2.1,2.6,3.3,4.7,2.8
              c0.2,0,0.5-0.1,0.7-0.2c2.3-0.9,3.5-3.5,2.8-5.9C46.5,26.2,44.5,25,42.4,25.5
            `}
    />
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={`
              M115,49.3c-1,0.2-1.8-0.4-2-1.3c-0.2-0.9,0.3-1.8,1.3-2.1c0.9-0.2,1.8,0.4,2,1.3
              C116.4,48.2,115.9,49.1,115,49.3 M114.1,45.5c-1.2,0.3-2,1.4-1.7,2.6c0.3,1.2,1.4,1.9,2.7,
              1.6c1.2-0.3,2-1.4,1.7-2.6 C116.5,45.9,115.3,45.2,114.1,45.5
            `}
    />
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={`
              M114.4,47.5l-0.2,0.1l-0.1-0.5c0.1,0,0.1-0.1,0.3-0.1c0.3-0.1,0.5,0,0.5,0.2
              C114.9,47.3,114.7,47.4,114.4,47.5 M115,47.5L115,47.5c0.2-0.1,0.4-0.3,
              0.3-0.5c0-0.2-0.2-0.3-0.3-0.4c-0.2,0-0.4-0.1-0.8,0
              s-0.6,0.2-0.8,0.2l0.4,1.8l0.5-0.1l-0.2-0.7l0.2-0.1c0.3-0.1,0.4,0,0.5,0.2s0.2,0.4,0.3,0.4l0.5-0.1c-0.1-0.1-0.1-0.2-0.3-0.4
              C115.4,47.6,115.3,47.5,115,47.5
            `}
    />
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={`
              M106.6,28.7l0.3-0.4c0.6-0.8,0.9-1.1,0.7-1.7c-0.1-0.4-0.5-0.6-1.1-1c-0.3-0.2-0.4-0.3-0.9-0.5
              c-0.6-0.4-0.9-0.4-1.3-0.3c-0.6,0.2-1.2,0.8-1.8,1.5c-0.7,0.9-1.8,
              2.4-2.5,3.5c-2.6,4-4.7,7.9-6.9,12.7c-2.5,5.5-3.8,7.6-4.9,8.3
              c-0.7,0.4-1.8,0.5-1.9-2c0-0.4,0.1-0.8,0.1-1.1c0.4-4.8,1.4-8.7,2.1-11.4c0.6-2.1,0-2.5-1.3-3c-1.1-0.5-1.3-0.6-2.1-0.9
              c-0.9-0.3-1.5-0.3-2.1,0.1c-0.3,0.1-0.4,0.3-0.6,0.4l0,0c-0.2,0.2-0.3,0.4-0.5,0.6c-2.8,
              4-6.7,10.5-9.5,15.9 c0.9-6.7,1.5-9.6,1.9-11.7c0.3-1.8,
              0.1-2.2-1-2.6c-0.7-0.3-1.5-0.6-2.7-1c-1.1-0.4-1.8,0-2.4,0.8c-1.9,2.7-7.2,11-10.6,17.3
              c1.1-5.9,2-10.9,2.4-12.6c0.3-1.6,0.3-2.3-1-2.9c-1-0.4-1.9-0.8-2.8-1.1c-1.7-0.6-2.1,
              0.2-2.5,1.2c-0.1,0.4-0.3,1-0.6,1.8 c-4,12.4-8,19.7-9.9,20.8c-0.8,0.5-1.3,0.4-1.6,0.2c-0.9-0.6-0.7-2.8-0.6-3.7c0.4-3.3,1.5-8.6,2.7-13.7c0.5-2,0.1-2.4-1.3-3
              c-0.9-0.4-1.7-0.6-2.7-1c-1.5-0.5-2.2,0.1-2.6,1.8c-1,4.2-3.4,14.7-3.1,20.1c0.3,4.1,2.3,5.4,4.7,
              5.4c1.8,0,3.6-0.9,4.6-1.7 c2.1-1.7,4.8-5.3,7.9-12.6c-0.4,2.4-0.7,5.2-0.8,7c-0.1,2,
              0.3,2.4,1.5,2.8c0.6,0.2,0.8,0.3,1.7,0.6c0.8,0.3,2.1,0.5,2.8-0.9
              c1-1.9,6.1-12.1,10.5-18.7c-0.5,3.7-1.3,9.3-1.4,12.8c-0.1,1.5,0.4,2,1.8,
              2.5l2.1,0.7c0.5,0.2,1.8,0.5,2.6-1 c0.7-1.4,5-10.3,8.7-16.1c-0.1,0.3-0.1,0.6-0.2,
              0.9c-0.8,4.2-1.1,7.3-0.9,9.4c0.2,1.9,1,4.8,4.4,4.9c1.3,0.1,3.1-0.4,4.8-1.5
              c3.2-2.2,4.7-5.9,7.9-13c0.8-1.8,1.7-3.6,2.6-5.3c0.3,2,1.4,3.8,3.3,5.9c1.4,1.6,2.1,
              3.1,2,4.4c-0.1,1.9-1.8,2.4-2.3,2.5 c-1.4,0.3-2.7-0.5-3.3-2.1c-0.5-1.3-0.8-1.7-1.5-1.5c-0.3,0-0.5,
              0.2-0.8,0.6c-0.2,0.2-0.6,0.8-0.9,1.5c-0.6,1.4-0.2,2.6,0.6,3.4 c1.6,1.8,4.2,2
              ,7.1,1.3c5.1-1.2,7.5-4.5,7.1-9.3c-0.1-0.7-0.2-2.1-1.4-4c-0.9-1.5-2.6-3.5-3.2-4.2C103.9,
              32.6,105.3,30.4,106.6,28.7
            `}
    />
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={`
              M56,16.6c1.1-0.3,1.8-1.1,1-2.2s-0.8-1-1.6-2s-1.4-0.9-3-0.5C33.9,16.5,10,24.1,5,25.7
              c-2.3,0.8-1.6,1.9-0.9,3.1C4.5,29.4,4.3,29,4.9,30c0.8,1.2,1.2,2,3.5,1.2c1.8-0.7,10.1-3.4,
              16.5-5.5C24,29.7,23,34,21,43.3 c-1.2,5.4-2.3,12.5-4.5,22.4c-0.2,1-0.4,2.3,1.2,
              2.9c1.4,0.5,2.3,0.8,4,1.3s2.4-0.2,2.7-1.6c0.6-3.1,7-37.2,8.6-45.2
              C40.5,21,48.1,18.7,56,16.6
            `}
    />
  </svg>
);
