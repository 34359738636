export enum SupportedFontTypes {
  'woff' = 'woff',
  'woff2' = 'woff2',
  'otf' = 'otf',
  'ttfVariableLegacy' = 'truetype-variations',
  'ttfVariable' = 'truetype supports variations',
}

export enum SupportedFonts {
  FlameBold,
  FlameRegular,
  FlameSans,
  ChickenSansBold,
  ChickenSansRegular,
  SofiaProBlack,
  SofiaProBold,
  SofiaProRegular,
  SilverspoonRegular,
  SilverspoonBold,
  SilverspoonLight,
  Monserrat,
}

export interface IFontSource {
  source: SupportedFonts;
  type:
    | SupportedFontTypes.otf
    | SupportedFontTypes.woff
    | SupportedFontTypes.woff2
    | SupportedFontTypes.ttfVariable
    | SupportedFontTypes.ttfVariableLegacy;
}

export interface IFontFace {
  name: string;
  sources: IFontSource[];
  weight: string;
  style: string;
  display: string;
}
