import React, { forwardRef } from 'react';

import styled, { DefaultTheme } from 'styled-components';

import { ButtonSizes, IBaseButtonProps, IButtonProps } from './types';

const getFontSize = ({ size, theme }: { size?: ButtonSizes; theme: DefaultTheme }) => {
  const fontSizes = {
    [ButtonSizes.SMALL]: theme.fontSize?.button.SMALL,
    [ButtonSizes.LARGE]: theme.fontSize?.button.LARGE,
  };
  return fontSizes[size || ButtonSizes.LARGE];
};

const getIconSize = ({ size }: { size?: ButtonSizes }) => {
  const iconSizes = {
    [ButtonSizes.SMALL]: '16px',
    [ButtonSizes.LARGE]: '24px',
  };

  return iconSizes[size || ButtonSizes.LARGE];
};

const BaseButton = styled.button<IBaseButtonProps>`
  font-family: ${({ theme }) => theme.fontFamily?.primary};
  font-weight: ${({ theme }) => theme.fontWeight?.button};
  font-size: ${getFontSize};
  text-align: center;
  text-transform: ${({ theme }) => theme.textTransform?.button};
  letter-spacing: ${({ theme }) => theme.letterSpacing?.button};
  // The button should not be more than 100% of the component that it's being wrapped in.
  max-width: 100%;

  // Adding an ellipsis if the button text makes the button larger than the string
  ${(p) =>
    p.wrapText
      ? `
      white-space: normal;
      overflow-wrap: break-word;`
      : `
      white-space: 
      nowrap; overflow: hidden;
      text-overflow: ellipsis;`}

  color: ${({ disabled, theme }) =>
    disabled ? theme.color?.buttonsDisabledLabel : theme.token('text-button-primary')};

  background-color: ${({ disabled, theme, transparent }) => {
    if (transparent) {
      return 'transparent';
    }
    return disabled
      ? theme.color?.buttonsDisabledBackground
      : theme.token('background-button-primary-default');
  }};

  outline: 0;

  border: 0;
  border-radius: ${(p) => (p.isSquare ? 'none' : '9999rem')};

  width: ${(p) => (p.fullWidth ? '100%' : 'auto')};

  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};

  margin: 0;
  padding: ${({ size, onlyIcon }) => {
    if (size === ButtonSizes.SMALL) {
      return onlyIcon ? '0.375rem' : '0.375rem 1.5rem';
    }
    return onlyIcon ? '0.75rem' : '0.75rem 3rem';
  }};

  transition: all 0.125s ease;

  display: ${({ hasStartIcon, hasEndIcon }) => (hasStartIcon || hasEndIcon ? 'flex' : 'block')};
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;

  div {
    > svg {
      & {
        align-items: center;
        height: ${getIconSize};
        width: ${getIconSize};
      }
      fill: ${({ theme, disabled }) =>
        disabled ? theme.color?.buttonsDisabledLabel : theme.token('text-button-primary')};

      stroke: ${({ theme, disabled }) =>
        disabled ? theme.color?.buttonsDisabledLabel : theme.token('text-button-primary')};
    }
    > svg[data-legacy-icon] {
      height: ${({ size }) => (size === ButtonSizes.SMALL ? '12px' : '16px')};
      width: ${({ size }) => (size === ButtonSizes.SMALL ? '12px' : '16px')};
    }
  }

  > span {
    margin-left: ${(p) => (p.hasStartIcon ? '0.5rem' : 0)};
    margin-right: ${(p) => (p.hasEndIcon ? '0.5rem' : 0)};
    /*  Large Buttons Works for PLK transform: translateY(-7%);
    none for BK and for TH transform: translateY(-2%);*/
    /*  SMALL Buttons Works for PLK transform: translateY(-5%);
    none for BK and for TH NONE;*/
  }

  &:hover {
    background-color: ${({ disabled, theme }) =>
      disabled ? theme.color?.buttonsDisabledBackground : theme.color?.buttonsPrimaryHover};
  }

  &:active {
    outline: 0;
    transform: ${(p) => (p.disabled ? 'none' : 'scale(0.95)')};
    background-color: ${({ disabled, theme }) =>
      disabled ? theme.color?.buttonsDisabledBackground : theme.color?.buttonsPrimaryActive};
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.color?.buttonsFocused};
  }
`;

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      children,
      className = '',
      disabled = false,
      onClick,
      size,
      startIcon,
      endIcon,
      onlyIcon = !children,
      fullWidth = false,
      isSquare = false,
      transparent = false,
      wrapText = false,
      ...rest
    },
    forwardedRef,
  ) => (
    <BaseButton
      aria-disabled={disabled}
      className={className}
      disabled={disabled}
      onClick={onClick}
      size={size}
      hasStartIcon={!!startIcon}
      hasEndIcon={!!endIcon}
      onlyIcon={onlyIcon}
      fullWidth={fullWidth}
      isSquare={isSquare}
      transparent={transparent}
      ref={forwardedRef}
      wrapText={wrapText}
      {...rest}
    >
      {startIcon}
      {children && (startIcon || endIcon ? <span>{children}</span> : children)}
      {endIcon}
    </BaseButton>
  ),
);
