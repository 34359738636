import React from 'react';

import { SVGIconComponent } from '../types';

export const tendersAndWings: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 76 51" {...props}>
    <title>{title}</title>
    <path d="M56.315 27c1.134-6.255-2.428-10.916-7.908-11.299-4.843-.338-13.011 1.94-19.395 1.172-.828-.1-1.968-.447-3.313-.936-2.53-2.705-2.856-7.13.042-11.464C29.473-1.106 33.617-.375 37.55.854c7.474 2.336 13.72 9.395 16.485 12.483 2.28 2.547 9.817 8.638 12.503 13.663H56.315zm-38.622 0C11.556 23.796 3.49 19.167 5.786 14.955c3.15-5.778 18.758 3.819 23.366 4.43 5.613.743 12.767-1.233 17.023-.888 4.315.35 7.307 3.767 7.204 8.503H17.693zM2.791 30h70.462a2 2 0 0 1 1.903 2.614l-5.486 17A2 2 0 0 1 67.767 51H8.558a2 2 0 0 1-1.894-1.357l-5.767-17A2 2 0 0 1 2.79 30z" />
  </svg>
);
