import React from 'react';

import { SVGIconComponent } from '../types';

export const close: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path
      d="m7.2441 7.2441c0.32543-0.32544 0.85307-0.32544 1.1785 0l3.5774 3.5774 3.5774-3.5774c0.3254-0.32544 0.8531-0.32544 1.1785 0 0.3255 0.32543 0.3255 0.85307 0 1.1785l-3.5774 3.5774 3.5774 3.5774c0.3255 0.3254 0.3255 0.8531 0 1.1785-0.3254 0.3255-0.8531 0.3255-1.1785 0l-3.5774-3.5774-3.5774 3.5774c-0.32544 0.3255-0.85308 0.3255-1.1785 0-0.32544-0.3254-0.32544-0.8531 0-1.1785l3.5774-3.5774-3.5774-3.5774c-0.32544-0.32544-0.32544-0.85308 0-1.1785z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  </svg>
);
