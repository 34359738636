import * as React from 'react';

import { SVGIconComponent } from '../types';

export const submitFeedback: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="M18 0H2C.9 0 .01.9.01 2L0 20l4-4h14c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2Zm-7 12H9v-2h2v2Zm0-4H9V4h2v4Z" />
  </svg>
);
