import React from 'react';

import { SVGIconComponent } from '../types';

export const scanRewards: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 200 574" {...props}>
    <title>{title}</title>
    <g fillRule="evenodd" clipRule="evenodd">
      <path
        d="M175.6,299.2H87.8c-13.4,0-24.4,11.2-24.4,25.1v150.3H158c5.2,0,9.5,4.3,9.5,9.5v63.4h8.1
    c13.4,0,24.4-11.2,24.4-25.1V324.2C200,310.4,189.1,299.2,175.6,299.2z"
      />
      <path
        d="M131.1,227.8c0.9,0,1.6,0.7,1.6,1.6v32.9c0,0.9-0.7,1.6-1.6,1.6s-1.6-0.7-1.6-1.6v-32.9
    C129.4,228.6,130.2,227.8,131.1,227.8z M149.8,229l8.3,26.3c0.3,0.9-0.2,1.8-1.1,2c-0.9,0.3-1.8-0.2-2.1-1.1l-8.3-26.3
    c-0.3-0.9,0.2-1.8,1.1-2C148.6,227.6,149.5,228.1,149.8,229z M116,229.5l-8.3,26.4c-0.7,2.1-3.8,1.1-3.1-1l8.3-26.4
    C113.5,226.5,116.7,227.4,116,229.5z M167.9,0.1c9.8,0.4,17.5,8.5,17.6,18.3v169.8c0,10.1-8.2,18.3-18.4,18.4H96.6
    c-10.1,0-18.3-8.2-18.4-18.4V18.4c0-9.8,7.8-17.9,17.7-18.3H167.9z M167.8,3.4H96c-8.1,0.4-14.4,7-14.4,15.1v169.7
    c0,8.3,6.8,15.1,15.1,15.1h70.6c8.3,0,15.1-6.8,15.1-15.1V18.4C182.2,10.4,175.9,3.7,167.8,3.4z M139.3,176.8c1.7,0,3,1.3,3,3v7.1
    c0,1.7-1.3,3-3,3h-14.8c-1.7,0-3-1.3-3-3v-7.1c0-1.7,1.3-3,3-3H139.3z M165.6,176.8c1.7,0,3,1.3,3,3v7.1c0,1.7-1.3,3-3,3h-14.8
    c-1.7,0-3-1.3-3-3v-7.1c0-1.7,1.3-3,3-3H165.6z M113,176.8c1.7,0,3,1.3,3,3v7.1c0,1.7-1.3,3-3,3H98.2c-1.7,0-3-1.3-3-3v-7.1
    c0-1.7,1.3-3,3-3H113z M139.3,158.3c1.7,0,3,1.3,3,3v7.1c0,1.7-1.3,3-3,3h-14.8c-1.7,0-3-1.3-3-3v-7.1c0-1.7,1.3-3,3-3H139.3z
    M165.6,158.3c1.7,0,3,1.3,3,3v7.1c0,1.7-1.3,3-3,3h-14.8c-1.7,0-3-1.3-3-3v-7.1c0-1.7,1.3-3,3-3H165.6z M113,158.3c1.7,0,3,1.3,3,3
    v7.1c0,1.7-1.3,3-3,3H98.2c-1.7,0-3-1.3-3-3v-7.1c0-1.7,1.3-3,3-3H113z M139.3,139.8c1.7,0,3,1.3,3,3v7c0,1.7-1.3,3-3,3h-14.8
    c-1.7,0-3-1.3-3-3v-7c0-1.7,1.3-3,3-3H139.3z M165.6,139.8c1.7,0,3,1.3,3,3v7c0,1.7-1.3,3-3,3h-14.8c-1.7,0-3-1.3-3-3v-7
    c0-1.7,1.3-3,3-3H165.6z M113,139.8c1.7,0,3,1.3,3,3v7c0,1.7-1.3,3-3,3H98.2c-1.7,0-3-1.3-3-3v-7c0-1.7,1.3-3,3-3H113z M139.3,121.4
    c1.7,0,3,1.3,3,3v7.1c0,1.7-1.3,3-3,3h-14.8c-1.7,0-3-1.3-3-3v-7.1c0-1.7,1.3-3,3-3H139.3z M165.6,121.4c1.7,0,3,1.3,3,3v7.1
    c0,1.7-1.3,3-3,3h-14.8c-1.7,0-3-1.3-3-3v-7.1c0-1.7,1.3-3,3-3H165.6z M113,121.4c1.7,0,3,1.3,3,3v7.1c0,1.7-1.3,3-3,3H98.2
    c-1.7,0-3-1.3-3-3v-7.1c0-1.7,1.3-3,3-3H113z M167.6,18v91.2H96.1V18H167.6z"
      />
      <g transform="translate(17.515789, 121.800000)" />
      <polygon
        style={{ fill: '#FFFFFF' }}
        points="99.1,389.2 92.9,389.2 92.9,364.4 117.8,364.4 117.8,370.5 99.1,370.5 "
      />
      <polygon
        style={{ fill: '#FFFFFF' }}
        points="170.5,442 145.7,442 145.7,435.8 164.4,435.8 164.4,417.1 170.5,417.1 "
      />
      <polygon
        style={{ fill: '#FFFFFF' }}
        points="170.5,389.2 164.4,389.2 164.4,370.5 145.7,370.5 145.7,364.4 170.5,364.4 "
      />
      <polygon
        style={{ fill: '#FFFFFF' }}
        points="117.8,442 92.9,442 92.9,417.1 99.1,417.1 99.1,435.8 117.8,435.8 "
      />
      <polygon
        style={{ fill: '#FFFFFF' }}
        points="158.1,429.5 128.6,429.5 128.6,406.3 105.3,406.3 105.3,400.1 134.8,400.1 134.8,423.3 158.1,423.3 "
      />
      <polygon
        style={{ fill: '#FFFFFF' }}
        points="158.1,417.1 151.8,417.1 151.8,406.2 145.7,406.2 145.7,417.1 139.5,417.1 139.5,400.1 158.1,400.1 "
      />
      <path
        id="Shape"
        style={{ fill: '#FFFFFF' }}
        d="M124,395.4h-18.6v-18.6H124V395.4z M111.6,389.1h6.1V383h-6.1V389.1z"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M158.1,395.4h-18.6v-18.6h18.6V395.4z M145.7,389.1h6.1V383h-6.1L145.7,389.1L145.7,389.1z"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M124,429.5h-18.6v-18.6H124V429.5z M111.6,423.3h6.1v-6.1h-6.1V423.3z"
      />
      <rect x="128.6" y="376.8" style={{ fill: '#FFFFFF' }} width="6.2" height="18.6" />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M118.8,319.4h25.9c1.4,0,2.6,1.4,2.6,3.1c0,1.7-1.2,3.1-2.6,3.1h-25.9c-1.4,0-2.6-1.4-2.6-3.1
    C116.2,320.7,117.4,319.4,118.8,319.4L118.8,319.4z"
      />
      <path
        d="M164.5,484.1c0-3.6-2.9-6.5-6.5-6.5H10c-3.6,0-6.5,2.9-6.5,6.5v79.5c0,3.6,2.9,6.5,6.5,6.5h148
    c3.6,0,6.5-2.9,6.5-6.5V484.1z"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M105.2,516.8c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h22.1c0.4,0,0.7,0.3,0.7,0.7s-0.3,0.7-0.7,0.7
    H105.2z"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M38.9,516.8c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h21.8c0.4,0,0.7,0.3,0.7,0.7
    s-0.3,0.7-0.7,0.7H38.9z"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M38.7,545c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h88.4c0.4,0,0.7,0.3,0.7,0.7
    s-0.3,0.7-0.7,0.7H38.7z"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M46.1,535.7l-3-4.6h-0.6v4.6h-3v-13.2h5.3c3,0,4.4,2.2,4.4,4.4c0,1.9-1,3.5-3.1,4l3.4,4.8H46.1z
    M42.5,525.2v3.6h1.9c1.3,0,1.9-0.9,1.9-1.7s-0.6-1.8-1.8-1.8H42.5z M54.6,533.1h5.6v2.6h-8.5v-13.2H60v2.6h-5.4v2.7h4.7v2.7h-4.7
    V533.1z M75.6,522.6h3.2l-4.4,13.2h-2.3l-1.7-6.7l-1.7,6.7h-2.3l-4.4-13.2h3.2l2.4,7.9l1.6-6h2.4l1.6,6L75.6,522.6z M87.8,535.7
    l-1-3.1h-4.6l-1,3.1h-3.2l4.8-13.2h3.2l4.8,13.2H87.8z M83.1,530.1H86l-1.5-4.6L83.1,530.1z M98.9,535.7l-3-4.6h-0.6v4.6h-3v-13.2
    h5.3c3,0,4.4,2.2,4.4,4.4c0,1.9-1,3.5-3.1,4l3.4,4.8H98.9z M95.3,525.2v3.6h1.9c1.3,0,1.9-0.9,1.9-1.7s-0.6-1.8-1.8-1.8H95.3z
    M104.5,535.7v-13.2h5.2c3.8,0,5.9,2.9,5.9,6.6c0,3.7-2.2,6.5-5.9,6.5H104.5z M107.5,533.1h2.2c2,0,3-1.9,3-3.9c0-2.1-1-4-3-4h-2.2
    V533.1z M121.9,535.9c-2.8,0-4.7-1.6-4.7-4.1h3c0,0.8,0.8,1.5,1.9,1.5c1,0,1.7-0.4,1.7-1.3c0-0.5-0.3-1-1.4-1.4l-1.6-0.5
    c-2.9-0.8-3.4-2.6-3.4-3.8c0-2.4,2-4,4.6-4c2.5,0,4.4,1.5,4.4,4.1h-3c0-0.8-0.5-1.5-1.5-1.5c-0.9,0-1.5,0.6-1.5,1.3
    c0,0.3,0.1,0.9,1.3,1.2l1.4,0.4c1.9,0.6,3.8,1.5,3.8,3.9C126.7,534.7,124.3,535.9,121.9,535.9z"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M75.7,510.4c0.2-0.5,0.5-0.6,0.8-0.3c0.3,0.2,0.5,0.3,0.7,0.4c0.3,0.3,0.4,0.4,0.2,0.9
    c-0.7,1.3-1.3,2.7-1.6,3.6c-0.1,0.2-0.3,0.9-0.1,1.1c0.1,0.1,0.2,0.1,0.4,0c0.6-0.2,2.1-2,4-5.1c0.1-0.2,0.2-0.4,0.3-0.5
    c0.2-0.3,0.3-0.4,0.8-0.2c0.2,0.1,0.5,0.3,0.7,0.5c0.3,0.2,0.3,0.4,0.1,0.9c-0.2,0.4-0.8,1.8-1.5,3.3c1.3-1.5,3.3-3.5,4-4.1
    c0.2-0.2,0.4-0.2,0.7-0.1c0.3,0.2,0.5,0.3,0.7,0.5c0.3,0.2,0.3,0.3,0.1,0.8c-0.2,0.5-0.6,1.3-1.3,3.1c1.1-1.3,2.6-2.8,3.6-3.8
    c0.1-0.1,0.1-0.1,0.2-0.1l0,0l0,0c0,0,0.1-0.1,0.2-0.1c0.2-0.1,0.4,0,0.6,0.1c0.2,0.1,0.2,0.2,0.5,0.4c0.3,0.2,0.4,0.4,0.2,0.9
    c-0.4,0.7-0.9,1.7-1.3,3c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.7,0.2,0.7,0.4,0.7c0.4-0.1,0.8-0.6,1.9-2c0.9-1.2,1.7-2.1,2.7-3.1
    c0.3-0.3,0.7-0.6,0.9-0.8c0.2-0.2,0.4-0.3,0.6-0.3c0.1,0,0.2,0,0.3,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.2,0.2,0.2,0.2,0.3
    c0,0.2-0.1,0.3-0.3,0.4l0,0l-0.1,0.1c-0.5,0.4-1,0.9-0.6,1.7c0.1,0.3,0.5,0.9,0.6,1.4c0.2,0.6,0.2,1,0.1,1.2
    c-0.2,1.3-1.1,2.1-2.5,2.1c-0.8,0-1.5-0.2-1.9-0.8c-0.2-0.3-0.2-0.6,0.1-1c0.1-0.2,0.3-0.3,0.3-0.3c0.1-0.1,0.2-0.1,0.3-0.1
    c0.2,0,0.2,0.1,0.3,0.5c0.1,0.5,0.4,0.8,0.8,0.8c0.1,0,0.6,0,0.8-0.5c0.1-0.4,0-0.8-0.3-1.4c-0.4-0.7-0.5-1.3-0.5-1.8
    c-0.4,0.4-0.7,0.9-1.1,1.3c-1.3,1.8-2,2.7-3,3.1c-0.5,0.2-1,0.2-1.4,0.1c-0.9-0.3-1-1.1-0.9-1.6c0.1-0.6,0.4-1.5,0.8-2.6
    c0-0.1,0.1-0.2,0.1-0.3c-1.4,1.4-3.1,3.5-3.4,3.9c-0.3,0.4-0.7,0.2-0.8,0.1l0,0l-0.5-0.3c-0.3-0.2-0.4-0.4-0.3-0.8
    c0.3-1,0.8-2.4,1.2-3.4c-1.6,1.5-3.7,4-4.1,4.5c-0.3,0.3-0.6,0.2-0.8,0.1c-0.2-0.1-0.3-0.2-0.4-0.3c-0.3-0.2-0.4-0.3-0.2-0.9
    c0.1-0.5,0.4-1.2,0.7-1.9c-1.3,1.8-2.3,2.6-3,3c-0.3,0.1-0.9,0.3-1.4,0.2c-0.6-0.1-1.1-0.7-0.9-1.8
    C73.9,514.3,75.2,511.5,75.7,510.4z M96.5,516.8c0.4,0,0.6,0.3,0.6,0.6c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.6-0.3-0.6-0.6
    C95.9,517.1,96.2,516.8,96.5,516.8z M96.5,516.9c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5
    C97,517.2,96.8,517,96.5,516.9z M81.8,503.6c0.5,0,0.6,0,0.8,0.3c0.1,0.3,0.2,0.3,0.3,0.6c0.1,0.3-0.1,0.5-0.4,0.5
    c-2.3,0.1-4.5,0.2-6.7,0.4c-0.9,2.1-4.9,11.1-5.2,11.9c-0.2,0.3-0.4,0.5-0.8,0.3c-0.4-0.2-0.7-0.4-1-0.6c-0.4-0.3-0.3-0.6-0.2-0.9
    c1.2-2.6,2-4.5,2.7-5.9c1.1-2.4,1.7-3.6,2.2-4.6c-1.9,0.2-4.4,0.4-4.9,0.5c-0.7,0.1-0.7-0.1-0.9-0.5c-0.1-0.3-0.1-0.2-0.2-0.4
    c-0.1-0.4-0.2-0.7,0.4-0.8C69.3,504.3,76.3,503.7,81.8,503.6z M78.1,506.7c0.6,0,1.1,0.5,1.1,1.1c0,0.7-0.5,1.3-1.1,1.4
    c-0.1,0-0.1,0-0.2,0c-0.6,0-1.1-0.5-1.1-1.1c0-0.7,0.5-1.3,1.1-1.4C78,506.7,78,506.7,78.1,506.7z"
      />
    </g>
  </svg>
);
