import * as React from 'react';

import { SVGIconComponent } from '../types';

export const cateringBirthday: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="M2 17H22V19H2V17ZM13.84 7.79C13.94 7.55 14 7.28 14 7C14 5.9 13.1 5 12 5C10.9 5 10 5.9 10 7C10 7.28 10.06 7.55 10.16 7.79C6.25 8.6 3.27 11.93 3 16H21C20.73 11.93 17.75 8.6 13.84 7.79Z" />
  </svg>
);
