import React from 'react';

import { SVGIconComponent } from '../types';

export const order: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 28 29" {...props}>
    <title>{title}</title>
    <g fillRule="evenodd">
      <path d="M21.575 28.2375H23.65C24.7 28.2375 25.5625 27.4375 25.6875 26.4125L27.75 5.8125H21.5V0.75H19.0375V5.8125H12.825L13.2 8.7375C15.3375 9.325 17.3375 10.3875 18.5375 11.5625C20.3375 13.3375 21.575 15.175 21.575 18.175V28.2375ZM0.25 26.9875V25.75H19.0375V26.9875C19.0375 27.675 18.475 28.2375 17.775 28.2375H1.5125C0.8125 28.2375 0.25 27.675 0.25 26.9875ZM19.0375 18.2375C19.0375 8.2375 0.25 8.2375 0.25 18.2375H19.0375ZM0.275 20.75H19.025V23.25H0.275V20.75Z" />
    </g>
  </svg>
);
