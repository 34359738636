import React from 'react';

import { SVGIconComponent } from '../types';

export const paymentCash: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="m19 14v-8c0-1.1-0.9-2-2-2h-14c-1.1 0-2 0.9-2 2v8c0 1.1 0.9 2 2 2h14c1.1 0 2-0.9 2-2zm-9-1c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm13-6v11c0 1.1-0.9 2-2 2h-17v-2h17v-11h2z" />
  </svg>
);
