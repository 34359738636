/**
 * Style Primitives - USE IN CSS ONLY WHEN DESIGN TOKENS ARE NOT APPROPRIATE
 */
export const primitive = {
  // Brand-agnostic primitives go here

  // colors
  $black: '#000',
  $white: '#fff',
  $error: 'rgb(209,69,69)',
  $disabledText: 'rgba(0,0,0,0.55)',
  $disabledTextReversed: 'rgba(255,255,255,0.51)',
  $blackOpacity70: 'rgba(0,0,0,0.7)',
  $blackOpacity30: 'rgba(0,0,0,0.3)',
  $blackOpacity10: 'rgba(0,0,0,0.1)',
  $blackOpacity4: 'rgba(0,0,0,0.04)',
  $whiteOpacity30: 'rgba(255,255,255,0.3)',
  $whiteOpacity10: 'rgba(255,255,255,0.1)',
  $whiteOpacity4: 'rgba(255,255,255,0.04)',

  // spacing
  $spacing0: '0',
  $spacing1: '4px',
  $spacing2: '8px',
  $spacing3: '12px',
  $spacing4: '16px',
  $spacing5: '20px',
  $spacing6: '24px',
  $spacing7: '32px',
  $spacing8: '40px',
  $spacing9: '48px',
  $spacing10: '56px',
  $spacing11: '80px',

  /** Burger-King-only primitives */
  bk: {
    // colors
    $bbqBrown: 'rgb(80,35,20)',
    $mayoEggWhite: 'rgb(245,235,220)',
    $fieryRed: 'rgb(214,35,0)',
    $crunchyGreen: 'rgb(25,135,55)',
    $flamingOrange: 'rgb(255,135,50)',
    $meltyYellow: 'rgb(255,170,0)',
    $fieryRedOpacity10: 'rgba(214,35,0,0.1)',
    $fieryRedDarken10: 'rgb(163,27,0)',

    // text styles
    /** @deprecated Use the CSS variable for this font instead: var(--font-hero) */
    $hero: '32px/36px "Flame", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerOne) */
    $headerOne: '24px/24px "Flame", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerTwo) */
    $headerTwo: '18px/20px "Flame", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerThree) */
    $headerThree:
      '16px/20px "Flame", "Cooper Black", "Helvetica Neue", Helvetica, Arial, sans-serif',
    $headerFour:
      '13px/16px "Flame", "Cooper Black", "Helvetica Neue", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyOne) */
    $copyOne: '15px/20px "Flame Sans", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyTwo) */
    $copyTwo: '12px/16px "Flame Sans", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    $formUtility: '11px/12px "Flame Sans", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',

    // text styles for Desktop
    /** @deprecated Use the CSS variable for this font instead: var(--font-hero) */
    $heroDesktop: '56px/56px "Flame Bold", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerOne) */
    $headerOneDesktop: '30px/32px "Flame", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerTwo) */
    $headerTwoDesktop: '24px/28px "Flame", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerThree) */
    $headerThreeDesktop:
      '18px/20px "Flame", "Cooper Black", "Helvetica Neue", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyOne) */
    $copyOneDesktop:
      '16px/24px "Flame Sans", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyTwo) */
    $copyTwoDesktop:
      '13px/20px "Flame Sans", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
  },

  /** Love-That-Wing-only primitives */
  ltw: {
    // colors
    $loveDark: 'rgb(31,31,31)',
    $loveLight: 'rgb(249,243,228)',
    $loveRed: 'rgb(219,30,18)',
    $loveTeal: 'rgb(151,206,185)',
    $loveOrange: 'rgb(255,154,44)',
    $loveYellow: 'rgb(253,189,16)',
    $loveDarkLighten10: 'rgb(57,57,57)',

    /** @deprecated Use the color primitive $loveLight instead */
    $offWhite: 'rgb(249,243,227)',
    /** @deprecated Use the color primitive $loveDark instead */
    $nolaDark: 'rgb(31,31,31)',
    /** @deprecated Use the color primitive $loveTeal instead */
    $nolaTeal: 'rgb(0,166,158)',
    /** @deprecated Use a primitive from the new LTW color palette instead */
    $nolaPurple: 'rgb(145,25,135)',

    // text styles
    /** @deprecated Use the CSS variable for this font instead: var(--font-hero) */
    $hero: '32px/36px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerOne) */
    $headerOne: '24px/28px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerTwo) */
    $headerTwo: '18px/24px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerThree) */
    $headerThree: '16px/20px "chicken_sans_bold", sans-serif',
    $headerFour: '13px/16px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyOne) */
    $copyOne: '15px/20px "chicken_sans", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyTwo) */
    $copyTwo: '12px/16px "chicken_sans", sans-serif',
    $formUtility: '11px/12px "chicken_sans", sans-serif',

    // text styles for Desktop
    /** @deprecated Use the CSS variable for this font instead: var(--font-hero) */
    $heroDesktop: '56px/56px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerOne) */
    $headerOneDesktop: '30px/32px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerTwo) */
    $headerTwoDesktop: '24px/28px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerThree) */
    $headerThreeDesktop: '18px/20px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyOne) */
    $copyOneDesktop: '16px/24px "chicken_sans", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyTwo) */
    $copyTwoDesktop: '13px/20px "chicken_sans", sans-serif',
  },

  /** Popeyes-only primitives */
  plk: {
    // colors
    $nolaDark: 'rgb(31,31,31)',
    $nolaLight: 'rgb(245,241,239)',
    $popeyesOrange: 'rgb(255,125,0)',
    $burntOrange: 'rgb(242,86,0)',
    $nolaTeal: 'rgb(0,166,158)',
    $nolaPurple: 'rgb(145,25,135)',
    $nolaPurpleDark: 'rgba(88,16,82)',
    $butterYellow: 'rgb(255,200,0)',
    $spicyRed: 'rgb(217,15,6)',
    $burntOrangeOpacity10: 'rgba(242,86,0,0.1)',
    $burnOrangeDarken10: 'rgb(191,86,0)',

    // text styles
    /** @deprecated Use the CSS variable for this font instead: var(--font-hero) */
    $hero: '32px/36px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerOne) */
    $headerOne: '24px/28px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerTwo) */
    $headerTwo: '18px/24px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerThree) */
    $headerThree: '16px/20px "chicken_sans_bold", sans-serif',
    $headerFour: '13px/16px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyOne) */
    $copyOne: '15px/20px "chicken_sans", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyTwo) */
    $copyTwo: '12px/16px "chicken_sans", sans-serif',
    $formUtility: '11px/12px "chicken_sans", sans-serif',

    // text styles for Desktop
    /** @deprecated Use the CSS variable for this font instead: var(--font-hero) */
    $heroDesktop: '56px/56px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerOne) */
    $headerOneDesktop: '30px/32px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerTwo) */
    $headerTwoDesktop: '24px/28px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerThree) */
    $headerThreeDesktop: '18px/20px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyOne) */
    $copyOneDesktop: '16px/24px "chicken_sans", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyTwo) */
    $copyTwoDesktop: '13px/20px "chicken_sans", sans-serif',
  },

  /** Tim-Horton-only primitives */
  th: {
    // colors
    $coffeeBrown: 'rgb(63,32,33)',
    $alwaysRed: 'rgb(200,16,46)',
    $donutCream: 'rgb(221,203,164)',
    $donutCreamLighten20: 'rgb(249,245,238)',
    $alwaysRedOpacity10: 'rgba(200,16,46,0.1)',
    $alwaysRedDarken10: 'rgb(153,12,35)',

    // text styles
    /** @deprecated Use the CSS variable for this font instead: var(--font-hero) */
    $hero: '32px/36px "sofia_pro_bold", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerOne) */
    $headerOne: '24px/28px "sofia_pro_bold", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerTwo) */
    $headerTwo: '18px/24px "sofia_pro_bold", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerThree) */
    $headerThree: '16px/20px "sofia_pro_bold", Helvetica, Arial, sans-serif',
    $headerFour: '13px/16px "sofia_pro_bold", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyOne) */
    $copyOne: '15px/20px "sofia_pro", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyTwo) */
    $copyTwo: '12px/16px "sofia_pro", Helvetica, Arial, sans-serif',
    $formUtility: '11px/12px "sofia_pro", Helvetica, Arial, sans-serif',

    // text styles for Desktop
    /** @deprecated Use the CSS variable for this font instead: var(--font-hero) */
    $heroDesktop: '56px/56px "sofia_pro_bold", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerOne) */
    $headerOneDesktop: '30px/32px "sofia_pro_bold", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerTwo) */
    $headerTwoDesktop: '24px/28px "sofia_pro_bold", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerThree) */
    $headerThreeDesktop: '18px/20px "sofia_pro_bold", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyOne) */
    $copyOneDesktop: '16px/24px "sofia_pro", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyTwo) */
    $copyTwoDesktop: '13px/20px "sofia_pro", Helvetica, Arial, sans-serif',
  },

  fhs: {
    $fhsRed: 'rgba(196, 0, 0, 1)',
    $fhsRedDarken10: 'rgba(176, 0, 0, 1)',
    $fhsRedOpacity10: 'rgba(196, 0, 0, 0.1)',
    $fhsNavy: 'rgba(8, 13, 58, 1)',
    $fhsNavyDarken10: 'rgba(7, 12, 52, 1)',
    $houseLight: 'rgba(242, 242, 246, 1)',
    $fhsBrass: 'rgba(255, 205, 120, 1)',
    $fhsCopper: 'rgba(213, 113, 5, 1)',
    $fhsBrown: 'rgba(140, 75, 0, 1)',
    $fluoroGreen: 'rgba(240, 255, 0, 1)',
    $fhsGreen: 'rgba(46, 100, 0, 1)',
    $fhsDarkGreen: 'rgba(60, 70, 0, 1)',
    $houseRed: 'rgba(196,0,0)',
    $houseYellow: 'rgba(255,205,120)',

    // text styles
    /** @deprecated Use the CSS variable for this font instead: var(--font-hero) */
    $hero: '30px/32px Silverspoon Bold, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerOne) */
    $headerOne: '24px/28px Silverspoon Bold, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerTwo) */
    $headerTwo: '18px/18px Silverspoon Bold, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerThree) */
    $headerThree: '16px/20px Silverspoon Bold, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerFour) */
    $headerFour: '14px/16px Silverspoon Bold, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerFive) */
    $headerFive: '12px/16px Silverspoon Bold, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyOne) */
    $copyOne: '16px/20px Monserrat, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyTwo) */
    $copyTwo: '12px/16px Monserrat, sans-serif',
    $formUtility: '12px/18px Monserrat, sans-serif',

    // text styled form desktop
    /** @deprecated Use the CSS variable for this font instead: var(--font-hero) */
    $heroDesktop: '48px/48px Silverspoon Bold, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerOne) */
    $headerOneDesktop: '30px/32px Silverspoon Bold, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerTwo) */
    $headerTwoDesktop: '24px/28px Silverspoon Bold, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerThree) */
    $headerThreeDesktop: '18px/20px Silverspoon Bold, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerFour) */
    $headerFourDesktop: '16px/18px Silverspoon Bold, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyOne) */
    $copyOneDesktop: '16px/20px Monserrat, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyTwo) */
    $copyTwoDesktop: '14px/16px Monserrat, sans-serif',
  },
} as const;
