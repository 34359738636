import React from 'react';

import { SVGIconComponent } from '../types';

export const map: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="m21.444 2-0.1777 0.03333-5.9334 2.3-6.6666-2.3333-6.2667 2.1111c-0.23333 0.07778-0.4 0.27778-0.4 0.53333v16.8c0 0.3112 0.24444 0.5556 0.55556 0.5556l0.17777-0.0333 5.9333-2.3 6.6666 2.3333 6.2667-2.1111c0.2333-0.0778 0.4-0.2778 0.4-0.5333v-16.8c0-0.31112-0.2444-0.55556-0.5556-0.55556zm-11.667 2.7444 4.4444 1.5556v12.956l-4.4444-1.5556v-12.956zm-5.5556 1.1 3.3333-1.1222v13l-3.3333 1.2889v-13.167zm15.556 12.311-3.3334 1.1222v-12.989l3.3334-1.2889v13.156z" />
  </svg>
);
