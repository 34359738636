import React from 'react';

import { SVGIconComponent } from '../types';

export const cateringTwo: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 28 29" {...props}>
    <title>{title}</title>
    <path d="M22.866 17.165c0-4.258-3.14-7.773-7.218-8.362v-.095c0-.668-.537-1.208-1.203-1.208-.665 0-1.203.54-1.203 1.208v.095c-4.079.589-7.218 4.104-7.218 8.362v1.209h16.842v-1.209zm-7.218-6.645c-3.577 0-6.547 2.627-7.115 6.059-.054.33-.33.586-.664.586-.354 0-.645-.288-.614-.641.325-3.699 3.424-6.608 7.19-6.608 1.395 0 2.695.408 3.8 1.099-.808-.317-1.68-.495-2.597-.495z" />
    <rect width="19.557" height="1.559" x="4.667" y="19.941" rx=".779" />
  </svg>
);
