import React from 'react';

import { SVGIconComponent } from '../types';

export const hotDrink: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 15 20" {...props}>
    <title>{title}</title>
    <path
      d="M4,3.25h6A1.75,1.75,0,0,1,11.74,5v.08l-.58,12a1.74,1.74,0,0,1-1.75,1.67H4.59a1.74,1.74,0,0,1-1.75-1.67l-.58-12A1.76,1.76,0,0,1,3.93,3.25Z"
      style={{
        strokeWidth: '1.5px',
        fillRule: 'evenodd',
      }}
    />
    <path
      d="M6,13,8.5,8.5"
      style={{
        fill: 'none',
        strokeWidth: '1.5px',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      }}
    />
    <rect
      x="1.25"
      y="1.25"
      width="11.5"
      height="3.5"
      rx="1"
      style={{
        strokeWidth: '1.5px',
      }}
    />
  </svg>
);
