import React from 'react';

import { VisuallyHidden } from '../visually-hidden';

import { ParticleAnimation } from './loading-animation.styled';
import { ILoadingAnimationProps } from './types';

function LoadingAnimation({ loadingMessage, 'data-testid': testId }: ILoadingAnimationProps) {
  return (
    <ParticleAnimation data-testid={testId}>
      <span />
      <span />
      <span />
      <VisuallyHidden aria-live="polite">{loadingMessage}</VisuallyHidden>
    </ParticleAnimation>
  );
}

export default LoadingAnimation;
