import React from 'react';

import { SVGIconComponent } from '../types';

export const seafood: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 65 56" {...props}>
    <title>{title}</title>
    <path
      d="M44.705 33.796c11.048 1.466 16.301 6.04 17.903 9.82 3.473-7.014 3.212-15.154-1.096-19.044-6.9-6.238-13.891-5.39-16.976.176-3.89 7.011-7.217 9.642-16.278 5.25-5.08-2.463-7.618-6.136-8.896-8.91 3.155-2.835 7.457-8.769 6.869-16.092-.653-2.49-3.05-4.075-5.43-3.527-1.059.245-1.87.894-2.492 1.725C17.03.831 14.202-.483 11.333.164 8.467.834 6.522 3.245 6.417 5.92c-.909-.481-1.932-.71-3-.463-2.38.534-3.84 2.997-3.307 5.526 2.772 6.835 8.402 10.056 12.418 11.292-.54 5.273.338 11.884 3.348 17.885 1.618-2.672 4.655-6.294 10.133-8.419 0 0-5.295 4.89-7.977 12.058 3.501 5.044 8.873 9.182 16.491 11.083-1.89-8.234 2.29-17.699 2.29-17.699-.25 8.753 1.926 15.02 3.73 18.686.233.02.437.077.67.083 7.611.458 13.445-2.438 17.447-6.688 3.585-9.097-13.955-15.468-13.955-15.468"
      fillRule="evenodd"
    />
  </svg>
);
