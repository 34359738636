import React from 'react';

import { SVGIconComponent } from '../types';

export const mobileRaggedEdge: SVGIconComponent = ({ title = '', ...props }) => (
  <svg width="100%" viewBox="0 0 414 13" {...props}>
    <title>{title}</title>
    <path
      d="M0 4.2h22c9 0 17.9 0 26.9-.1 7.9 0 16.2-.6 24.1-.6 1.6 0 3.2 0 4.8.1 15 .5 30.1 2.8 45.3 2.8 5.6 0 11.3-.3 16.9-1.1 5.2-.7 9.1-2.1 14.7-2.5 2.2-.2 18.6.2 24.1.5 14.2.8 27.5 2.2 41.9 2.2 3.1 0 6.3-.1 9.5-.2 13-.6 25.8-1.8 39-2.2 15-.5 29.8-1.1 44.8-1.3 1.9.2 3.8.4 5.8.4h.6c2 0 4-.2 5.9-.4 4.4.1 8.8.3 13.2.4 6.9.3 13.7.5 20.6.5 7.9 0 15.7-.4 23.4-1.5 2.9-.4 5.9-.8 8.8-1.2H414v13H0V4.2z"
      fillRule="evenodd"
    />
  </svg>
);

export const desktopRaggedEdge: SVGIconComponent = ({ title = '', ...props }) => (
  <svg width="100%" viewBox="0 0 1440 13" {...props}>
    <title>{title}</title>
    <path
      d="M0 13v-2.7h18.9c6.6 0 13.2.1 19.9.1 6.7.1 13.4.1 20 .1 11.2 0 22.4-.2 33.4-.7 2.1-.1 4.2-.2 6.4-.2 2.1 0 4.3 0 6.4.1 2.1 0 4.3.1 6.4.1 1.9 0 3.8 0 5.7-.1 6.9-.3 13.4-1.3 20.6-1.4h.9c3.7 0 7.1.1 10.8.2h1.1c2 0 3.8-.1 5.5-.1 1.8-.1 3.5-.1 5.4-.1 1.5 0 3 0 4.7.2 3.6.3 6.8.4 10 .4 3.5 0 7-.2 10.8-.5 5.4-.1 10.7-.2 16.1-.5 3.2.2 6.4.4 10.1.4 3.8.1 7.3-.1 11.1.2 2.7.2 5.6 1 8.3 1.2.6 0 1.2.1 1.8.1 4.5 0 8.4-.9 12.6-1.4 2.9-.3 5.7-.4 8.4-.4 7.8 0 15.2.9 23.2 1h.3c.5 0 1 0 1.5-.1 3.7.2 7.4.3 11.2.4h1.8c13.3 0 26.2-1.6 39.3-2.3.4 0 .8 0 1.2-.1h1.2c3.4 0 6.7 0 9.7-.3h2.1c3.9 0 7.8 0 11.6.1 3.9.1 7.8.1 11.7.1h2.1c12.5-.1 25.1-.2 37.6-.2 9.2 0 18.3.1 27.4.4h.7c2.3.4 4.5.7 7.4.7h.7c2.4 0 4.1-.2 6-.4 4.5.1 9.1.1 13.8.1 13 0 26.1-.3 38.3-.9 9.3-.4 18.5-.7 27.8-.9 3.2.1 6.6.2 9.9.2 4.6 0 9.4-.1 14.1-.2 15.9.2 31.7.8 47.4 1.7 9.7.6 19.4.7 29.1.7 9.6 0 19.2-.2 28.9-.4 21.3-.5 42.8 0 64.1-1.3 4.4-.3 8.7-.4 13-.4 3.7 0 7.4.1 11.1.1 3.7.1 7.4.1 11.2.1h4.6c22.6-.4 45.2-1.1 67.9-1.5h.5c2.9.2 5.7.5 8.6.7 1.2.1 2.2.1 3.1.1 2.6 0 3.9-.3 7.1-.8.7-.1 1.5-.2 2.2-.3 8.5-.1 17.1-.1 25.7-.1H927l99.1-.3h22c8.9 0 17.9 0 26.9-.1 7.9 0 16.2-.6 24.1-.6 1.6 0 3.2 0 4.8.1 15 .5 30.1 2.8 45.3 2.8 5.6 0 11.3-.3 16.9-1.1 5.2-.7 9.1-2.1 14.7-2.5 2.2-.2 4.5-.2 6.8-.2 5.8 0 11.8.4 17.3.7 14.2.8 27.5 2.2 41.9 2.2 3.1 0 6.3-.1 9.5-.2 13-.6 25.8-1.8 39-2.2 15-.5 29.7-1.1 44.8-1.3 1.9.2 3.8.4 5.8.4h.6c2 0 4-.2 5.9-.4 4.4.1 8.8.3 13.2.4 6.9.3 13.7.5 20.5.5 7.9 0 15.7-.4 23.4-1.5 2.9-.4 5.9-.8 8.8-1.2h21.7l-.3 13H0z"
      fillRule="evenodd"
    />
  </svg>
);
