import styled, { css } from 'styled-components';

export const PlainIconStyles = styled.svg<{ $disabled?: boolean }>`
  stroke-width: 0;
  stroke: ${(p) => p.theme.token('icon-form-input-default')};
  fill: ${(p) => p.theme.token('icon-form-input-default')};

  /* Doubled the specificity here to work around the wrapping div re-enabling
    pointer events that it disables on itself */
  &[aria-hidden][aria-hidden] {
    pointer-events: none;
  }

  ${(p) =>
    p.$disabled &&
    css`
      stroke: ${p.theme.token('icon-disabled')};
      fill: ${p.theme.token('icon-disabled')};
    `}
`;
