import React from 'react';

import { SVGIconComponent } from '../types';

export const sauces: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 52 39" {...props}>
    <title>{title}</title>
    <path
      d="M48.858 21.692C48.405 31.312 38.575 39 26.514 39 14.454 39 4.623 31.313 4.17 21.692H2a2 2 0 0 1-2-2V16a2 2 0 0 1 2-2h48a2 2 0 0 1 2 2v3.692a2 2 0 0 1-2 2h-1.142zM8 11h38c-2.333-1.198-4.667-1.997-7-2.396-3.5-.599-6.5 0-7.5-1.797-1.663-2.988 6.5-4.028 5-5.038-.852-.574-8.89-4.135-18 .845C16.636 3.634 13.136 6.428 8 11z"
      fillRule="evenodd"
    />
  </svg>
);
