import React from 'react';

import { SVGIconComponent } from '../types';

export const coffeeCup: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 15 21" {...props}>
    <title>{title}</title>
    <path fill="none" d="M2 5l1.93 15h7.14L13 5H2zM12.19 1H2.81l-.5 1h10.38l-.5-1zM1 3v1h13V3H1z" />
    <path d="M14 2h-.19L13 .28a.51.51 0 0 0-.5-.28h-10a.51.51 0 0 0-.45.28L1.19 2H1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1v.13l1.92 15a1 1 0 0 0 1 .87h7.14a1 1 0 0 0 1-.87l1.94-15V5a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zM2.81 1h9.38l.5 1H2.31zm8.26 19H3.93L2 5h11zM1 3h13v1H1V3" />
  </svg>
);
