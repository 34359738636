import React from 'react';

import { SVGIconComponent } from '../types';

export const storeMarker: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 62 77" {...props}>
    <title>{title}</title>
    <path
      fillRule="evenodd"
      d="M21.2 60.4C8.9 56.3 0 44.7 0 31 0 13.9 13.9 0 31 0s31 13.9 31 31c0 15-10.6 27.4-24.7 30.4L29.5 77l-8.3-16.6z M39.3 21h-.2L38 18.7c-.1-.2-.3-.4-.6-.4H24.6c-.2 0-.5.1-.6.4L22.9 21h-.2c-.7 0-1.3.6-1.3 1.3v1.3c0 .7.6 1.3 1.3 1.3v.2l2.5 19.5c.1.6.6 1.1 1.3 1.1h9.2c.7 0 1.2-.5 1.3-1.1L39.4 25v-.2c.7 0 1.3-.6 1.3-1.3v-1.3c0-.7-.6-1.2-1.4-1.2zM25 19.6h12l.6 1.3H24.3l.7-1.3zm10.6 24.8h-9.2l-2.5-19.5H38l-2.4 19.5zm3.7-20.8H22.6v-1.9.6H39.3v1.3z"
    />
    <path d="M37 19.6H25l-.7 1.4h13.4zM23.9 24.9l2.5 19.5h9.2l2.5-19.5H23.9zM38.7 22.3v-.1h-16v-.5l-.1.5.1 1.3 16.6.1v-1.4h-.6z" />
  </svg>
);
