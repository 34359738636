import React from 'react';

import { SVGIconComponent } from '../types';

export const sadFace: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98 99" {...props}>
    <title>{title}</title>
    <g fillRule="evenodd">
      <path
        fill="#ECECEC"
        d="M98 51.000238C98 77.5100489 76.0617469 99 49 99 21.9377672 99 0 77.5100489 0 51.000238 0 24.4904271 21.9377672 3 49 3c27.0617469 0 49 21.4904271 49 48.000238"
      />
      <path
        fill="white"
        strokeWidth="3"
        d="M94.8462416 47.6500099c0 25.2379113-20.5606337 45.6968078-45.9230297 45.6968078-25.3628515 0-45.92302972-20.4588965-45.92302972-45.6968078 0-25.2379114 20.56017822-45.69726113 45.92302972-45.69726113 25.362396 0 45.9230297 20.45934973 45.9230297 45.69726113"
      />
      <path d="M91.0692772 29.4798739c1.4833862 4.411468 2.2886139 9.1324729 2.2886139 14.0406502 0 24.4198818-19.8938614 44.2157439-44.4346337 44.2157439S4.48816832 67.9404059 4.48816832 43.5205241c0-4.9081773.80522772-9.6291822 2.2890693-14.0406502-2.42798019 5.569399-3.7770099 11.7125517-3.7770099 18.1702266 0 25.2374581 20.56017818 45.6968079 45.92302968 45.6968079 25.3623961 0 45.9230297-20.4593498 45.9230297-45.6968079 0-6.4576749-1.3490297-12.6008276-3.7770099-18.1702266" />
      <path d="M38 36.5c0 1.9330083-1.5669917 3.5-3.5 3.5S31 38.4330083 31 36.5s1.5669917-3.5 3.5-3.5 3.5 1.5669917 3.5 3.5M66 36.5c0 1.9330083-1.5668685 3.5-3.4997248 3.5C60.5674188 40 59 38.4330083 59 36.5s1.5674188-3.5 3.5002752-3.5C64.4331315 33 66 34.5669917 66 36.5M68.4602317 59.6228808c-.067406.3621084-.3839406.6009458-.723703.5515468-1.4291881-.2062069-2.9025544-.329931-4.3567921-.4006306-1.6664752-.0802167-3.3356831-.0888275-4.9998812-.0253793-3.3265742.1341478-6.6399405.5275271-9.8745148 1.2589951-3.2341188.7233104-6.3967327 1.7597833-9.3894654 3.1674286-2.466693 1.1316453-4.7990297 2.5474483-6.9783366 4.1726305-.790198.5891626-1.8700594.399271-2.4320792-.4400591-.5943564-.8887291-.3693663-2.1413793.4991683-2.7124138 2.4762574-1.6283547 5.0850495-2.9983842 7.7890297-4.0452808 3.2782971-1.3029556 6.6713664-2.1830739 10.0867525-2.7273694 3.4167525-.5515468 6.8626535-.7423449 10.2894257-.6662069 1.7133862.0416946 3.4231288.1577143 5.1260396.3466995 1.4929505.1676847 2.9640397.3806896 4.4528911.6915862.3529703.0738719.5825149.4486699.5114654.8284532" />
    </g>
  </svg>
);
