import React from 'react';

import { SVGIconComponent } from '../types';

export const drive: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="m18.92 5.01c-0.2-0.59-0.76-1.01-1.42-1.01h-11c-0.66 0-1.21 0.42-1.42 1.01l-2.08 5.99v8c0 0.55 0.45 1 1 1h1c0.55 0 1-0.45 1-1v-1h12v1c0 0.55 0.45 1 1 1h1c0.55 0 1-0.45 1-1v-8l-2.08-5.99zm-12.42 9.99c-0.83 0-1.5-0.67-1.5-1.5s0.67-1.5 1.5-1.5 1.5 0.67 1.5 1.5-0.67 1.5-1.5 1.5zm11 0c-0.83 0-1.5-0.67-1.5-1.5s0.67-1.5 1.5-1.5 1.5 0.67 1.5 1.5-0.67 1.5-1.5 1.5zm-12.5-5 1.5-4.5h11l1.5 4.5h-14z" />
  </svg>
);
