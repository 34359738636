import styled from 'styled-components';

import { primitive } from '../../designTokens/primitives';

const LEFT_ICON_SIZE = '24px';
const RIGHT_ICON_SIZE = '16px';
const ELEMENT_HEIGHT = '24px';

export const Wrapper = styled.label`
  display: inline-block;
  position: relative;
  font-size: 0;
`;

export const LeftIconContainer = styled.div<{ $reversed: boolean }>`
  line-height: ${ELEMENT_HEIGHT};
  position: absolute;
  width: ${LEFT_ICON_SIZE};
  height: ${LEFT_ICON_SIZE};
  top: 0;
  left: 0;
  pointer-events: none;

  & > svg {
    vertical-align: middle;
    stroke: ${(p) =>
      p.$reversed ? p.theme.token('icon-reversed') : p.theme.token('icon-default')};
    fill: ${(p) => (p.$reversed ? p.theme.token('icon-reversed') : p.theme.token('icon-default'))};
  }
`;

export const RightIconContainer = styled(LeftIconContainer)`
  width: ${RIGHT_ICON_SIZE};
  height: ${RIGHT_ICON_SIZE};
  left: auto;
  right: 0;
`;

export const StyledLocaleSelect = styled.select<{ $reversed: boolean }>`
  appearance: none;
  border: none;
  background: none;
  font: ${(p) => p.theme.token('text-style-footer-location')};
  color: ${(p) => (p.$reversed ? p.theme.token('text-reversed') : p.theme.token('text-default'))};
  text-align: center;
  height: ${ELEMENT_HEIGHT};
  padding: 0 calc(${RIGHT_ICON_SIZE} + ${primitive.$spacing1}) 0
    calc(${LEFT_ICON_SIZE} + ${primitive.$spacing2});
  border-radius: ${(p) => p.theme.token('border-radius-input')};
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: ${(p) =>
      p.$reversed
        ? p.theme.token('box-shadow-pattern-reversed')
        : p.theme.token('box-shadow-pattern')};
  }
  /* For browsers that support :focus-visible, undo the :focus
    behavior we just enabled, and enable it only on :focus-visible.
    This way the focus appearance is still activated on keyboard focus,
    but does not linger after a click. The more specific selector of
    the hover styles keeps it from being overridden by the broad
    selector of &:not(:focus-visible). */
  &:not(:focus-visible) {
    box-shadow: none;
  }
  &:focus-visible {
    box-shadow: ${(p) =>
      p.$reversed
        ? p.theme.token('box-shadow-pattern-reversed')
        : p.theme.token('box-shadow-pattern')};
  }
`;
