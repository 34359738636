import styled from 'styled-components';

export const Wrapper = styled.div<{ $hasError?: boolean; $disabled?: boolean }>`
  font: ${(p) => p.theme.token('text-style-form-hint')};
  padding-top: 8px;
  text-align: left;
  color: ${({ $hasError, $disabled, theme: { token } }) => {
    if ($hasError) {
      return token('text-error');
    }
    if ($disabled) {
      return token('text-disabled');
    }
    return token('text-default');
  }};

  a {
    color: inherit;
  }
`;
