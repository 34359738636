import React from 'react';

import { SVGIconComponent } from '../types';

export const check: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="m9.0002 16.17-4.17-4.17-1.42 1.41 5.59 5.59 12-12-1.41-1.41-10.59 10.58z" />
  </svg>
);
