import React from 'react';

import { SVGIconComponent } from '../types';

export const edit: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 12.7 11.7" {...props}>
    <title>{title}</title>
    <path d="M11.3856337,0.714290757 L12.0200062,1.39068361 C12.7756278,2.1963571 12.7350527,3.46203685 11.9293792,4.21765845 C11.9265987,4.22026624 11.9238107,4.22286609 11.9210153,4.22545794 L6.28268331,9.45323531 C4.69029811,10.9296711 2.5524454,11.6718889 0.387739568,11.4998364 L0.387739568,11.4998364 C0.404139094,9.3784854 1.29543142,7.35797546 2.85106502,5.91561518 L8.56702281,0.615864308 C9.37373077,-0.132103282 10.6330655,-0.0881270627 11.3856337,0.714290757 Z" />
  </svg>
);
