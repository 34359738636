import * as React from 'react';

import { Icon } from '../icon';
import { Box } from '../layout';

import { SocialMediaData, SocialMediaLinkProps } from './types';

const SocialMediaLink = React.forwardRef<HTMLAnchorElement, SocialMediaLinkProps>(
  ({ href, icon }, forwardedRef) => {
    if (!href || !icon) {
      return null;
    }
    return (
      <a href={href} ref={forwardedRef}>
        <Box height={'20px'}>
          <Icon hoverColor={'icon-active'} color={'text-link-reversed'} icon={icon} title={icon} />
        </Box>
      </a>
    );
  },
);

/**
 * To add  a new social media link:
 *  1. Add new Icon in component library
 *  2. Add link for the icon here
 *  3. Add data to CMS
 */
export const SocialMediaLinks = ({ socialMediaData }: { socialMediaData: SocialMediaData }) => {
  const { facebookUrl, instagramUrl, tiktokUrl, twitterUrl, youtubeUrl } = socialMediaData;
  return (
    <>
      {facebookUrl?.locale && <SocialMediaLink icon={'facebook'} href={facebookUrl.locale} />}
      {instagramUrl?.locale && <SocialMediaLink icon={'instagram'} href={instagramUrl.locale} />}
      {tiktokUrl?.locale && <SocialMediaLink icon={'tiktok'} href={tiktokUrl.locale} />}
      {twitterUrl?.locale && <SocialMediaLink icon={'twitter'} href={twitterUrl.locale} />}
      {youtubeUrl?.locale && <SocialMediaLink icon={'youtube'} href={youtubeUrl.locale} />}
    </>
  );
};
