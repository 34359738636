import React from 'react';

import { SVGIconComponent } from '../types';

export const next: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="M7.5 20.23L9.30459 22L19.5 12L9.30459 2L7.5 3.77L15.8908 12L7.5 20.23Z" />
  </svg>
);
