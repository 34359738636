import React from 'react';

import { SVGIconComponent } from '../types';

export const mapleLeaf: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 25.8 26.31" {...props}>
    <title>{title}</title>
    <path d="M12.9 26.31l-.39-.48c-.63-.78-1.68-1-3.19-.81l-.58.1v-1.57H4.6l2.33-2.44C5.6 20 3.49 18.35.59 16L0 15.58l.66-.37C2.79 14 2.31 12.76 1.64 11l-.56-1.53 1.13.53c1.33.66 2.37.84 3.08.52A2.24 2.24 0 0 0 6.43 9l.26-1 1.75 2.19c-.12-1.71-.22-3.7-.31-5.91v-.82l.74.35a2.13 2.13 0 0 0 1.71.19 4.14 4.14 0 0 0 1.85-2.62L12.9 0l.47 1.35A4.14 4.14 0 0 0 15.22 4 2.13 2.13 0 0 0 17 3.83l.74-.35v.82c-.09 2.21-.19 4.2-.31 5.91L19.1 8l.27.94a2.24 2.24 0 0 0 1.14 1.59c.71.32 1.74.14 3.08-.52l1.13-.56-.56 1.55c-.67 1.77-1.15 3 1 4.22l.66.37-.59.46c-2.9 2.31-5 4-6.34 5.07l2.33 2.44h-4.16v1.57l-.58-.1c-1.51-.24-2.56 0-3.19.81zm-2.6-2.37a3.81 3.81 0 0 1 2.6.86 4.05 4.05 0 0 1 3.16-.8v-1.45h2.81L17.4 21l.42-.34c1.2-1 3.28-2.63 6.22-5-1.82-1.3-1.57-2.89-1.09-4.31a3.84 3.84 0 0 1-2.84.08 2.83 2.83 0 0 1-1.37-1.38l-2.62 3.35.13-1.64c.15-1.87.28-4.14.39-6.76a2.78 2.78 0 0 1-1.81-.11 4 4 0 0 1-1.93-2.07A4 4 0 0 1 11 4.89 2.78 2.78 0 0 1 9.16 5c.11 2.62.24 4.89.39 6.76l.13 1.64-2.62-3.31a2.83 2.83 0 0 1-1.37 1.38 3.84 3.84 0 0 1-2.84-.08c.48 1.42.73 3-1.09 4.31 2.94 2.34 5 4 6.22 5l.42.3-1.47 1.55h2.81V24z" />
  </svg>
);
