import React from 'react';

import { SVGIconComponent } from '../types';

export const settings: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="M19.14 12.9404C19.18 12.6404 19.2 12.3304 19.2 12.0004C19.2 11.6804 19.18 11.3604 19.13 11.0604L21.16 9.48039C21.34 9.34039 21.39 9.07039 21.28 8.87039L19.36 5.55039C19.24 5.33039 18.99 5.26039 18.77 5.33039L16.38 6.29039C15.88 5.91039 15.35 5.59039 14.76 5.35039L14.4 2.81039C14.36 2.57039 14.16 2.40039 13.92 2.40039H10.08C9.83999 2.40039 9.64999 2.57039 9.60999 2.81039L9.24999 5.35039C8.65999 5.59039 8.11999 5.92039 7.62999 6.29039L5.23999 5.33039C5.01999 5.25039 4.76999 5.33039 4.64999 5.55039L2.73999 8.87039C2.61999 9.08039 2.65999 9.34039 2.85999 9.48039L4.88999 11.0604C4.83999 11.3604 4.79999 11.6904 4.79999 12.0004C4.79999 12.3104 4.81999 12.6404 4.86999 12.9404L2.83999 14.5204C2.65999 14.6604 2.60999 14.9304 2.71999 15.1304L4.63999 18.4504C4.75999 18.6704 5.00999 18.7404 5.22999 18.6704L7.61999 17.7104C8.11999 18.0904 8.64999 18.4104 9.23999 18.6504L9.59999 21.1904C9.64999 21.4304 9.83999 21.6004 10.08 21.6004H13.92C14.16 21.6004 14.36 21.4304 14.39 21.1904L14.75 18.6504C15.34 18.4104 15.88 18.0904 16.37 17.7104L18.76 18.6704C18.98 18.7504 19.23 18.6704 19.35 18.4504L21.27 15.1304C21.39 14.9104 21.34 14.6604 21.15 14.5204L19.14 12.9404ZM12 15.6004C10.02 15.6004 8.39999 13.9804 8.39999 12.0004C8.39999 10.0204 10.02 8.40039 12 8.40039C13.98 8.40039 15.6 10.0204 15.6 12.0004C15.6 13.9804 13.98 15.6004 12 15.6004Z" />
  </svg>
);
