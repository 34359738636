import styled from 'styled-components';

import { primitive } from '../../designTokens/primitives';
import { mediaQuery } from '../../globalStyles';
import { Grid } from '../grid';

export const Container = styled.footer`
  width: 100%;
  background-color: ${(p) => p.theme.token('background-footer')};
`;

export const TopHalfWrapper = styled(Grid)`
  padding-top: ${primitive.$spacing8};
  padding-bottom: ${primitive.$spacing7};

  @media ${mediaQuery.desktopLarge} {
    padding-top: ${primitive.$spacing10};
    padding-bottom: ${primitive.$spacing8};
  }
`;

export const BrandIconContainer = styled.div`
  display: none;

  @media ${mediaQuery.desktopSmall} {
    display: grid;
    grid-area: 1 / 1 / 1 / 3;
  }

  @media ${mediaQuery.desktopLarge} {
    grid-area: 1 / 1 / 1 / 4;
  }
`;

export const NavLinksContainer = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-area: 1 / 1 / 1 / 3;
  padding-bottom: ${primitive.$spacing7};
  grid-row-gap: ${primitive.$spacing3};

  @media ${mediaQuery.tablet} {
    grid-area: 1 / 1 / 1 / 8;
  }

  @media ${mediaQuery.desktopSmall} {
    grid-area: 1 / 3 / 1 / 5;
    padding-bottom: ${primitive.$spacing0};
  }

  @media ${mediaQuery.desktopLarge} {
    grid-area: 1 / 4 / 1 / 7;
    grid-row-gap: ${primitive.$spacing4};
  }
`;

export const StyledMainLink = styled.a`
  font: ${(p) => p.theme.token('text-style-footer-large')};
  color: ${(p) => p.theme.token('text-link-reversed')};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const MiscLinksContainerLeft = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-row-gap: ${primitive.$spacing3};

  @media ${mediaQuery.tablet} {
    grid-area: 2 / 1 / 2 / 5;
  }

  @media ${mediaQuery.desktopSmall} {
    grid-area: 1 / 5 / 1 / 7;
  }

  @media ${mediaQuery.desktopLarge} {
    grid-area: 1 / 7 / 1 / 10;
    grid-row-gap: ${primitive.$spacing2};
  }
`;

export const MiscLinksContainerRight = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-area: 2 / 2 / 3 / 3;
  grid-row-gap: ${primitive.$spacing3};

  @media ${mediaQuery.tablet} {
    grid-area: 2 / 5 / 2 / 9;
  }

  @media ${mediaQuery.desktopSmall} {
    grid-area: 1 / 7 / 1 / 9;
  }

  @media ${mediaQuery.desktopLarge} {
    grid-area: 1 / 10 / 1 / 12;
    grid-row-gap: ${primitive.$spacing2};
  }
`;

export const StyledMiscLink = styled.a`
  font: ${(p) => p.theme.token('text-style-footer-small')};
  color: ${(p) => p.theme.token('text-link-reversed')};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledHR = styled.hr`
  border-color: ${(p) => p.theme.token('border-color-reversed')};
  margin: 0 6%;

  @media ${mediaQuery.desktopLarge} {
    max-width: 1136px;
    margin: 0 auto;
  }
`;

export const BottomHalfWrapper = styled.div`
  display: grid;
  padding-top: ${primitive.$spacing7};
  padding-bottom: ${primitive.$spacing8};
  grid-row-gap: ${primitive.$spacing6};
  margin: 0 6%;
  justify-items: start;
  align-items: center;

  @media ${mediaQuery.desktopSmall} {
    grid-template-columns: 1fr auto;
  }

  @media ${mediaQuery.desktopLarge} {
    grid-template-columns: 1fr auto;
    padding: ${primitive.$spacing7} ${primitive.$spacing0} ${primitive.$spacing8} 0;
    max-width: 1136px;
    margin: auto;
  }
`;

export const MiniIconsContainer = styled.div`
  display: grid;
  grid-column-gap: ${primitive.$spacing7};
  grid-auto-flow: column;
  grid-auto-columns: min-width;
  align-items: center;
`;

export const CopyrightAndLanguageSelectorContainer = styled.div`
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  justify-items: start;
  align-items: center;

  @media ${mediaQuery.tablet} {
    display: grid;
    grid-area: 2/1/2/8;
    grid-auto-flow: column;
    align-items: center;
  }
  @media ${mediaQuery.desktopSmall} {
    grid-area: 1 / 2 / 1 / 3;
    display: grid;
    grid-auto-flow: column;
    justify-items: end;
    align-items: center;
  }
`;

export const Copyright = styled.p`
  font: ${(p) => p.theme.token('text-style-footer-small')};
  color: ${(p) => p.theme.token('text-link-reversed')};
  margin-bottom: ${primitive.$spacing6};
  margin-top: 0;

  @media ${mediaQuery.tablet} {
    margin-bottom: 0;
  }
`;

export const LanguageSelectorWrapper = styled.div`
  font: ${(p) => p.theme.token('text-style-footer-small')};
  color: ${(p) => p.theme.token('text-link-reversed')};

  &:hover {
    text-decoration: underline;
  }

  @media ${mediaQuery.tablet} {
    justify-self: end;
  }

  @media ${mediaQuery.desktopSmall} {
    padding-left: ${primitive.$spacing7};
  }
`;
