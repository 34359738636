import React from 'react';

import { SVGIconComponent } from '../types';

export const home: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="m10 20v-6h4v6h5v-8h3l-10-9-10 9h3v8h5z" />
  </svg>
);
