import React from 'react';

import { SVGIconComponent } from '../types';

export const language: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="m11.99 2c-5.52 0-9.99 4.48-9.99 10s4.47 10 9.99 10c5.53 0 10.01-4.48 10.01-10s-4.48-10-10.01-10zm6.93 6h-2.95c-0.32-1.25-0.78-2.45-1.38-3.56 1.84 0.63 3.37 1.91 4.33 3.56zm-6.92-3.96c0.83 1.2 1.48 2.53 1.91 3.96h-3.82c0.43-1.43 1.08-2.76 1.91-3.96zm-7.74 9.96c-0.16-0.64-0.26-1.31-0.26-2s0.1-1.36 0.26-2h3.38c-0.08 0.66-0.14 1.32-0.14 2s0.06 1.34 0.14 2h-3.38zm0.82 2h2.95c0.32 1.25 0.78 2.45 1.38 3.56-1.84-0.63-3.37-1.9-4.33-3.56zm2.95-8h-2.95c0.96-1.66 2.49-2.93 4.33-3.56-0.6 1.11-1.06 2.31-1.38 3.56zm3.97 11.96c-0.83-1.2-1.48-2.53-1.91-3.96h3.82c-0.43 1.43-1.08 2.76-1.91 3.96zm2.34-5.96h-4.68c-0.09-0.66-0.16-1.32-0.16-2s0.07-1.35 0.16-2h4.68c0.09 0.65 0.16 1.32 0.16 2s-0.07 1.34-0.16 2zm0.25 5.56c0.6-1.11 1.06-2.31 1.38-3.56h2.95c-0.96 1.65-2.49 2.93-4.33 3.56zm1.77-5.56c0.08-0.66 0.14-1.32 0.14-2s-0.06-1.34-0.14-2h3.38c0.16 0.64 0.26 1.31 0.26 2s-0.1 1.36-0.26 2h-3.38z" />
  </svg>
);
