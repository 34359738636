import React from 'react';

import { SVGIconComponent } from '../types';

export const voice: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="m12 15c1.66 0 2.99-1.34 2.99-3l0.01-6c0-1.66-1.34-3-3-3s-3 1.34-3 3v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1s-5.3-2.1-5.3-5.1h-1.7c0 3.42 2.72 6.23 6 6.72v3.28h2v-3.28c3.28-0.48 6-3.3 6-6.72h-1.7z" />
  </svg>
);
