import React from 'react';

import { SVGIconComponent } from '../types';

export const sodiumFlag: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 200 200" {...props}>
    <title>{title}</title>
    <path
      d="M100.2,7.8c4.3,0,8.2,2.2,10.3,6l80.1,139.4c2.1,3.7,2.1,8.2,0,11.9c-2.2,3.7-6,6-10.3,6H19.7c-4.3,0-8.2-2.2-10.3-6
		c-2.2-3.7-2.2-8.2,0-11.9L89.9,13.7C92,10,95.9,7.8,100.2,7.8L100.2,7.8L100.2,7.8 M100.2,0.1c-6.6,0-13.2,3.3-17,9.8L2.7,149.3
		c-7.5,13.1,1.9,29.4,17,29.4h160.6c15.1,0,24.5-16.3,17-29.3L117.2,9.9C113.4,3.4,106.8,0.1,100.2,0.1L100.2,0.1z"
    />
    <path d="M100,60.4c-1.5,0-2.8,0.5-2.8,1.2s1.2,1.2,2.8,1.2c1.5,0,2.8-0.5,2.8-1.2S101.5,60.4,100,60.4z" />
    <path d="M98,58.9c0-0.6-1.2-1.2-2.8-1.2c-1.5,0-2.8,0.5-2.8,1.2c0,0.6,1.2,1.2,2.8,1.2C96.7,60.1,98,59.5,98,58.9z" />
    <path d="M90.7,60.4c-1.5,0-2.8,0.5-2.8,1.2s1.2,1.2,2.8,1.2c1.5,0,2.8-0.5,2.8-1.2S92.2,60.4,90.7,60.4z" />
    <ellipse cx="108.1" cy="61.6" rx="2.8" ry="1.2" />
    <path d="M107.3,58.9c0-0.6-1.2-1.2-2.8-1.2c-1.5,0-2.8,0.5-2.8,1.2c0,0.6,1.2,1.2,2.8,1.2C106.1,60.1,107.3,59.5,107.3,58.9z" />
    <path
      d="M109.3,85.8L109.3,85.8c-0.5,0.1-0.8,0.5-0.7,1c0.9,5.2,5.5,34,1.9,59.1c-0.1,0.5,0.3,0.9,0.7,1c0.5,0.1,0.9-0.3,1-0.7
			c3.6-25.3-1-54.3-1.9-59.6C110.2,86.1,109.8,85.8,109.3,85.8z"
    />
    <path
      d="M90.1,85.8L90.1,85.8c-0.5-0.1-0.9,0.2-1,0.7c-0.9,5.3-5.5,34.3-1.9,59.6c0.1,0.5,0.5,0.8,1,0.7c0.5-0.1,0.8-0.5,0.7-1
			c-3.6-25.1,1-53.9,1.9-59.1C90.9,86.4,90.6,85.9,90.1,85.8z"
    />
    <path
      d="M187.5,155.1L107.4,15.7c-3.2-5.5-11.2-5.5-14.4,0L12.5,155.1c-3.2,5.5,0.8,12.4,7.2,12.4h160.7
				C186.7,167.5,190.6,160.6,187.5,155.1z M99.3,55.2c8.9,0,16.4,4,18.2,9.4c-5.1,1.5-11.4,2.4-18.2,2.4c-6.8,0-13.1-0.9-18.2-2.4
				C82.9,59.2,90.4,55.2,99.3,55.2z M81,67c0,0,17.7,5.7,36.4,0V72C98.8,79.6,81,72,81,72V67z M122.8,146.7l-12.7,7.6h-22l-12.4-7.6
				c-1.8-14.8-2.6-24.6-2.3-36.9c0.5-19.7,7-35.7,7-35.7s18.4,7.7,37.7,0h0c0,0,6.6,15.9,7.1,35.7
				C125.5,122.1,124.7,132,122.8,146.7z"
    />
  </svg>
);
