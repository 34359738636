import React, { FC } from 'react';

import styled, { css } from 'styled-components';

import { Button } from './Button';
import { InverseButton } from './InverseButton';
import { IBoxShadowButtonProps } from './types';

const BaseBoxShadowStyle = css`
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px 0px;

  &:focus {
    box-shadow: 0 0.5rem 1.25rem 0 rgba(0, 0, 0, 0.3);
  }
`;

const BaseBoxShadowButtonPrimary = styled(Button)<IBoxShadowButtonProps>`
  ${BaseBoxShadowStyle};
`;

const BaseBoxShadowButtonInverse = styled(InverseButton)<IBoxShadowButtonProps>`
  ${BaseBoxShadowStyle};
`;

export const BoxShadowButton: FC<IBoxShadowButtonProps> = ({
  children,
  inverse = false,
  ...rest
}) =>
  inverse ? (
    <BaseBoxShadowButtonInverse {...rest}>{children}</BaseBoxShadowButtonInverse>
  ) : (
    <BaseBoxShadowButtonPrimary {...rest}>{children}</BaseBoxShadowButtonPrimary>
  );
