import React from 'react';

import { SVGIconComponent } from '../types';

export const book: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 16 24" {...props}>
    <title>{title}</title>
    <g transform="translate(-16 -8)" fillRule="evenodd">
      <rect x={16} y={13} width={16} height={19} rx={0.5} />
      <path d="M29 11.5V8.694a.5.5 0 0 0-.658-.475l-8.419 2.807a.5.5 0 0 0 .158.974H28.5a.5.5 0 0 0 .5-.5z" />
    </g>
  </svg>
);
