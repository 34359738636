import React from 'react';

import { SVGIconComponent } from '../types';

export const arrowUp: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 11.26 7.1" {...props}>
    <title>{title}</title>
    <path d="M0 5.63L1.47 7.1l4.16-4.16L9.79 7.1l1.47-1.47L5.63 0 0 5.63z" />
  </svg>
);
