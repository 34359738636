import React from 'react';

import { SVGIconComponent } from '../types';

export const delivery: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path
      d="m19 7c0-1.1-0.9-2-2-2h-3v2h3v2.65l-3.48 4.35h-3.52v-5h-4c-2.21 0-4 1.79-4 4v3h2c0 1.66 1.34 3 3 3s3-1.34 3-3h4.48l4.52-5.65v-3.35zm-12 10c-0.55 0-1-0.45-1-1h2c0 0.55-0.45 1-1 1zm3-11h-5v2h5v-2zm6 10c0-1.66 1.34-3 3-3s3 1.34 3 3-1.34 3-3 3-3-1.34-3-3zm2 0c0 0.55 0.45 1 1 1s1-0.45 1-1-0.45-1-1-1-1 0.45-1 1z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  </svg>
);

export const deliveryPLK: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 89 72" {...props}>
    <title>{title}</title>
    <path
      d="M39.39,53.17c1.15-0.95,2.13-2.08,3.08-3.16c0.18-0.21,0.37-0.42,0.55-0.63c0.26-0.29,0.56-0.54,0.89-0.8
			c0.15-0.12,0.29-0.23,0.43-0.36c0.03-0.02,0.06-0.04,0.09-0.06c0.08-0.05,0.18-0.12,0.26-0.22c0.59-0.73,1.35-1.23,2.09-1.66
			c0.11-0.05,0.27-0.12,0.46-0.21c0.94-0.42,3.44-1.55,4.01-1.6c0.28-0.02,0.54-0.15,0.79-0.28c0.12-0.06,0.23-0.12,0.34-0.16
			c0.11-0.04,0.22-0.09,0.32-0.13c0.27-0.12,0.52-0.23,0.73-0.23c0.88-0.03,1.89-0.03,2.81,0.22c0.08,0.02,0.15,0.05,0.23,0.07
			c0.22,0.07,0.46,0.15,0.72,0.16c0.99,0.03,1.87,0.14,2.69,0.35c0.05,0.01,0.1,0.02,0.14,0.03c0.18,0.04,0.36,0.08,0.49,0.15
			c1.01,0.59,2.09,1.26,3.4,2.11c0.47,0.31,0.9,0.7,1.32,1.09c0.14,0.13,0.29,0.26,0.43,0.39c0.14,0.12,0.22,0.24,0.24,0.34
			c0.05,0.27,0.17,0.5,0.36,0.72c0.34,0.9,0.85,2.24,0.97,2.95c0.08,0.48,0.1,1.83,0.11,2.92c0.01,0.47,1.18,0.21,2.42-0.46
			c5.57-4.53,9.1-11.93,5.37-21.71c-2.87-7.51-8.95-18.72-8.95-18.72l0.07-0.02c2.41-0.61,2.87-3.73,0.06-5.41l2.01-1.86L65.4,5.78
			l1.1-4.02l-6.31,2.5c0.01-0.01,0.02-0.01,0.02-0.01s-1.6-3.34-4.03-3.36c-2.05-0.02-3.02,1.46-3.02,1.46s-0.55-1.52-1.95-1.94
			c-1.4-0.42-3.69,0.4-3.98,2.95c-0.33,2.85,1.98,4.32,2.74,4.56c0.9,0.29,2.14,0.87,4.75-0.23c0,2.62-0.01,8.69-0.02,11.74
			c-0.01,3.95,0.35,10.37-1.35,13.84c-1.7,3.48-3.63,4.92-6.19,4.62c-2.86-0.34-3.84-3.02-2.85-7.99c0.99-4.97,1.83-9.92-2.34-13.61
			c-4.17-3.69-11.67-4.09-20.33,6.36l5.66,6.83c0,0,3.03-4.45,6.76-1.46c3.73,2.98,1.12,9.11,0.78,12.64
			c-0.44,4.49,0.67,8.12,2.48,11.01c0.37,0.59,0.82,1.15,1.33,1.7C38.99,53.48,39.2,53.32,39.39,53.17z M60.74,6.69
			c0.64-0.33,1.47-0.01,1.84,0.71c0.37,0.72,0.15,1.58-0.5,1.91c-0.64,0.33-1.47,0.01-1.84-0.71C59.87,7.87,60.09,7.02,60.74,6.69z"
    />
    <path
      d="M35.68,55.55c-0.23-0.02-0.45-0.02-0.68-0.02c-0.61-0.01-1.22,0.03-1.82,0.06c-0.09,0-0.17,0.01-0.26,0.01
				c-2.75,0.14-5.5,0.39-8.24,0.45c-2.86,0.07-5.71,0.16-8.57,0.28c-6.6,0.27-5.21,1.35-0.48,1.4c3.45,0.04,6.91,0.05,10.37,0.06
				c2.2,0.01,4.41,0.08,6.61,0.12c1.08,0.02,2.16,0.03,3.24,0.02c0.48,0,0.97-0.06,1.44-0.05c0.35,0,0.84,0.05,1.17-0.09
				c0.11-0.05,0.2-0.18,0.15-0.3c-0.03-0.08-0.11-0.13-0.19-0.17c-0.64-0.36-1.2-0.88-1.6-1.49c-0.03-0.04-0.06-0.09-0.1-0.12
				c-0.05-0.03-0.11-0.05-0.17-0.06C36.25,55.6,35.96,55.57,35.68,55.55z"
    />
    <path
      d="M36.31,61.68c-2.98-0.2-6.27,0.19-9.17,0.48c-3.1,0.3-2.45,1.5-0.22,1.56c1.62,0.08,3.27,0.06,4.89,0.07
				c1.83,0.01,3.62,0.07,5.42-0.15c0.05-0.01,0.1-0.01,0.14-0.04c0.04-0.03,0.05-0.06,0.06-0.1c0.15-0.52,0.17-1.05,0.08-1.57
				c-0.01-0.04-0.02-0.08-0.05-0.11c-0.04-0.03-0.11-0.04-0.17-0.05C36.97,61.73,36.64,61.7,36.31,61.68z"
    />
    <path
      d="M22.06,52.12c1.55,0.03,3.1,0.05,4.65,0.06c0.94,0.01,1.93-0.02,2.86,0.08c0.8,0.09,1.61-0.05,2.43,0.03
				c0.37,0.04,0.78,0.09,1.09-0.13c-0.31-0.43-0.49-0.94-0.52-1.46c0-0.08-0.01-0.16-0.07-0.21c-0.05-0.04-0.12-0.04-0.19-0.04
				c-1.61,0.03-3.22-0.06-4.85,0.01c-1.73,0.07-3.45,0.18-5.18,0.32C19.19,51.03,19.84,52.08,22.06,52.12z"
    />
    <path
      d="M65.14,56.44c-0.02-0.27-0.04-0.54-0.06-0.82c-0.08-1.06-0.15-2.03-0.21-2.32c-0.08-0.35-0.38-0.94-0.7-1.56
				c-0.13-0.25-0.29-0.51-0.39-0.77c-0.09-0.25-0.08-0.53-0.23-0.76c-0.09-0.13-0.22-0.22-0.36-0.3c-0.34-0.22-0.64-0.43-0.87-0.77
				c-0.23-0.36-0.4-0.75-0.7-1.05c-0.09-0.09-0.18-0.18-0.25-0.27c-0.15-0.18-0.36-0.34-0.6-0.45c-0.42-0.2-2.21-0.91-2.73-1.04
				c-0.53-0.2-1.03-0.43-1.6-0.37C56,46.02,55.62,46,55.2,45.94c-0.64-0.1-1.32-0.07-2,0.07c-0.11,0.02-0.21,0.05-0.31,0.08
				c-1.02,0.28-2.02,0.64-2.98,1.07c-1.01,0.46-1.96,1.05-2.87,1.69c-1.02,0.71-2.04,1.33-2.88,2.25c-0.68,0.75-1.46,1.57-1.99,2.43
				c-0.18,0.3-0.36,0.59-0.54,0.89c-0.21,0.34-0.43,0.74-0.42,1.24c0,0.07-0.01,0.13-0.01,0.2c-0.05,0.07-0.1,0.14-0.16,0.21
				c-0.28,0.31-0.34,0.63-0.34,0.89c-0.08,0.26-0.06,0.5-0.02,0.68c0.01,0.05,0.01,0.11,0.02,0.16c0.08,0.6-0.34,1.32-0.33,1.96
				c0.01,0.83,0.02,1.64,0.17,2.47c0.02,0.12,0.06,0.36,0.11,0.66c0.17,1.04,0.32,2.01,0.41,2.29c0.11,0.34,0.46,0.9,0.83,1.49
				c0.15,0.24,0.34,0.48,0.46,0.73c0.12,0.24,0.12,0.52,0.3,0.73c0.1,0.12,0.24,0.2,0.38,0.27c0.36,0.19,0.68,0.37,0.93,0.69
				c0.26,0.34,0.46,0.71,0.79,0.99c0.1,0.08,0.19,0.16,0.28,0.25c0.17,0.17,0.39,0.31,0.63,0.4c0.44,0.16,2.28,0.72,2.81,0.8
				c0.54,0.15,1.06,0.34,1.63,0.23c0.42-0.08,0.8-0.1,1.23-0.07c0.65,0.04,1.33-0.04,1.98-0.25c0.1-0.03,0.2-0.07,0.31-0.1
				c0.99-0.36,1.96-0.81,2.88-1.32c0.97-0.54,1.86-1.22,2.72-1.93c0.57-0.48,1.16-0.94,1.71-1.45c0.3-0.28,0.67-0.45,0.98-0.71
				c0.4-0.32,0.58-0.71,0.88-1.12c0.2-0.28,0.41-0.56,0.62-0.84c0.24-0.32,0.49-0.7,0.52-1.2c0.02-0.3,0.15-0.59,0.37-0.83
				c0.36-0.39,0.36-0.81,0.33-1.09c-0.02-0.17-0.01-0.35,0-0.53c0.24-0.35,0.25-0.68,0.21-0.94c0.02-0.05,0.03-0.1,0.05-0.15
				C65.19,58.32,65.19,57.39,65.14,56.44z M63.07,58.24c-0.16,0.62-0.25,1.12-0.29,1.58c-0.03,0.31-0.14,0.6-0.32,0.8
				c-0.65,0.73-1.18,1.56-1.7,2.36c-0.1,0.15-0.2,0.31-0.3,0.46c-0.14,0.21-0.31,0.4-0.5,0.61c-0.08,0.09-0.17,0.18-0.25,0.27
				c-0.02,0.02-0.03,0.03-0.05,0.05c-0.05,0.04-0.1,0.09-0.15,0.17c-0.32,0.53-0.76,0.93-1.2,1.28c-0.07,0.04-0.16,0.1-0.28,0.18
				c-0.57,0.37-2.08,1.34-2.45,1.43c-0.02,0-0.03,0.01-0.05,0.01c-0.16,0.05-0.3,0.15-0.44,0.25c-0.07,0.05-0.14,0.1-0.21,0.14
				c-0.07,0.04-0.13,0.08-0.2,0.12c-0.16,0.1-0.32,0.2-0.46,0.22c-0.57,0.1-1.23,0.2-1.85,0.13c-0.05-0.01-0.1-0.01-0.16-0.02
				c-0.15-0.03-0.31-0.05-0.48-0.03c-0.58,0.07-3.73-0.66-4.63-1.07c-0.34-0.15-0.66-0.37-0.96-0.58c-0.11-0.07-0.21-0.14-0.32-0.21
				c-0.1-0.06-0.17-0.13-0.19-0.2c-0.06-0.17-0.16-0.31-0.3-0.43c-0.31-0.55-0.77-1.37-0.92-1.82c-0.1-0.3-0.24-1.18-0.35-1.89
				c-0.08-0.49-0.15-0.91-0.19-1.12c-0.07-0.33-0.09-0.67-0.11-1.01c-0.02-0.31-0.03-0.64-0.1-0.95c-0.05-0.24-0.03-0.51,0.04-0.78
				c0.16-0.62,0.25-1.12,0.29-1.58c0.03-0.31,0.14-0.6,0.32-0.8c0.65-0.73,1.18-1.56,1.7-2.36c0.1-0.15,0.2-0.31,0.3-0.46
				c0.14-0.21,0.31-0.4,0.5-0.61c0.08-0.09,0.17-0.18,0.25-0.27c0.02-0.02,0.03-0.03,0.05-0.05c0.05-0.04,0.1-0.09,0.15-0.17
				c0.32-0.53,0.76-0.93,1.2-1.28c0.07-0.04,0.16-0.1,0.28-0.18c0.57-0.37,2.09-1.34,2.45-1.43c0.18-0.04,0.33-0.15,0.48-0.26
				c0.07-0.05,0.14-0.1,0.21-0.14c0.07-0.04,0.13-0.08,0.2-0.12c0.16-0.1,0.32-0.2,0.46-0.22c0.57-0.1,1.23-0.21,1.84-0.13
				c0.05,0.01,0.1,0.02,0.16,0.02c0.15,0.03,0.31,0.05,0.48,0.03c0.65-0.08,1.23-0.09,1.78-0.03c0.03,0,0.06,0,0.09,0.01
				c0.12,0.01,0.24,0.02,0.33,0.05c0.72,0.29,1.48,0.62,2.42,1.04c0.34,0.15,0.66,0.37,0.97,0.58c0.11,0.07,0.21,0.14,0.32,0.21
				c0.1,0.06,0.17,0.13,0.19,0.2c0.06,0.17,0.16,0.31,0.3,0.43c0.31,0.55,0.77,1.37,0.92,1.82c0.1,0.3,0.24,1.18,0.35,1.89
				c0.08,0.49,0.15,0.91,0.19,1.12c0.07,0.33,0.09,0.67,0.11,1.01c0.02,0.31,0.03,0.64,0.09,0.95
				C63.16,57.69,63.15,57.96,63.07,58.24z"
    />
    <polygon points="63.76,68.96 64.31,68.96 64.31,70.61 64.56,70.61 64.56,68.96 65.12,68.96 65.12,68.72 63.76,68.72" />
    <polygon
      points="66.3,70.08 65.71,68.72 65.39,68.72 65.39,70.61 65.63,70.61 65.63,69.09 66.18,70.35 66.42,70.35
			66.98,69.09 66.98,70.61 67.22,70.61 67.22,68.72 66.89,68.72"
    />
  </svg>
);
