import React from 'react';

import { SVGIconComponent } from '../types';

export const error: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="m11 15h2v2h-2v-2zm0-8h2v6h-2v-6zm0.99-5c-5.52 0-9.99 4.48-9.99 10s4.47 10 9.99 10c5.53 0 10.01-4.48 10.01-10s-4.48-10-10.01-10zm0.01 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
  </svg>
);
