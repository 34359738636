import React from 'react';

import { SVGIconComponent } from '../types';

export const offer: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="m21.41 11.58-9-9c-0.36-0.36-0.86-0.58-1.41-0.58h-7c-1.1 0-2 0.9-2 2v7c0 0.55 0.22 1.05 0.59 1.42l9 9c0.36 0.36 0.86 0.58 1.41 0.58s1.05-0.22 1.41-0.59l7-7c0.37-0.36 0.59-0.86 0.59-1.41s-0.23-1.06-0.59-1.42zm-15.91-4.58c-0.83 0-1.5-0.67-1.5-1.5s0.67-1.5 1.5-1.5 1.5 0.67 1.5 1.5-0.67 1.5-1.5 1.5z" />
  </svg>
);
