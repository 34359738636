import React from 'react';

import { TheEveryButton, makeIconOnlyButton } from './the-every-button';
import {
  PrimaryButtonFn,
  PrimaryButtonProps,
  SecondaryButtonFn,
  SecondaryButtonProps,
  TertiaryButtonFn,
  TertiaryButtonProps,
} from './types';

export const PrimaryButton: PrimaryButtonFn = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  PrimaryButtonProps
>((props, forwardedRef) => (
  <TheEveryButton {...props} variant="primary" ref={forwardedRef as React.Ref<HTMLButtonElement>} />
));
export const SecondaryButton: SecondaryButtonFn = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  SecondaryButtonProps
>((props, forwardedRef) => (
  <TheEveryButton
    {...props}
    variant="secondary"
    ref={forwardedRef as React.Ref<HTMLButtonElement>}
  />
));
export const TertiaryButton: TertiaryButtonFn = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  TertiaryButtonProps
>((props, forwardedRef) => (
  <TheEveryButton
    {...props}
    variant="tertiary"
    ref={forwardedRef as React.Ref<HTMLButtonElement>}
  />
));

export const PreviousButton = makeIconOnlyButton({ icon: 'previous' });
export const NextButton = makeIconOnlyButton({ icon: 'next' });
