import React from 'react';

import { SVGIconComponent } from '../types';

export const previous: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="M17 20.23L15.1954 22L5 12L15.1954 2L17 3.77L8.60918 12L17 20.23Z" />
  </svg>
);
