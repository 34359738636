import * as React from 'react';

import { SVGIconComponent } from '../types';

export const fhsRewards: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 12 12" {...props}>
    <title>{title}</title>
    <path d="M3.32654 11.2045C3.12139 11.3696 2.90661 11.376 2.68223 11.2235C2.45784 11.0711 2.38411 10.8678 2.46105 10.6137L3.48041 7.29804L0.903154 5.46868C0.685177 5.30353 0.617861 5.0971 0.701205 4.84937C0.784549 4.60165 0.954442 4.47778 1.21089 4.47778H4.44207L5.46143 1.0668C5.49989 0.939759 5.57042 0.841304 5.67299 0.771433C5.77557 0.701561 5.88456 0.666626 5.99996 0.666626C6.11536 0.666626 6.22435 0.701561 6.32692 0.771433C6.4295 0.841304 6.50002 0.939759 6.53849 1.0668L7.55785 4.47778H10.789C11.0455 4.47778 11.2154 4.60165 11.2987 4.84937C11.3821 5.0971 11.3147 5.30353 11.0968 5.46868L8.51951 7.29804L9.53887 10.6137C9.61581 10.8678 9.54208 11.0711 9.31769 11.2235C9.0933 11.376 8.87853 11.3696 8.67338 11.2045L5.99996 9.18456L3.32654 11.2045Z" />
  </svg>
);
