import React from 'react';

import { SVGIconComponent } from '../types';

export const desserts: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 68 49" {...props}>
    <title>{title}</title>
    <path
      d="M44.703 4.53c.259-1.913 2.144-3.383 4.198-3.746A4.439 4.439 0 0 1 54.108 4.9a4.432 4.432 0 0 1 2.28-1.142 4.439 4.439 0 0 1 5.094 5.386c.403-.215.846-.37 1.32-.454a4.439 4.439 0 0 1 5.147 3.594 4.439 4.439 0 0 1-3.952 5.185l.003.005L.053 29.233 44.703 4.53zM.053 31.5L67.95 20v16.773a2 2 0 0 1-1.709 1.979l-63.895 9.41a2 2 0 0 1-2.292-1.978V31.5z"
      fillRule="evenodd"
    />
  </svg>
);
