import React from 'react';

import { SVGIconComponent } from '../types';

export const remove: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="M19 13H5V11H19V13Z" />
  </svg>
);
