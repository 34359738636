import React from 'react';

import { SVGIconComponent } from '../types';

export const curbside: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 28 22" {...props}>
    <title>{title}</title>
    <path d="M6,20 L28,20 L28,22 L4,22 L4,18 L0,18 L0,16 L4,16 L4,7 L2,7 L2,0 L7,0 L7,7 L5,7 L5,16 L6,16 L6,20 Z M6,1 L3,1 L3,6 L6,6 L6,1 Z" />
    <g xmlns="http://www.w3.org/2000/svg" id="Car" transform="translate(7.000000, 6.000000)">
      <path
        d="M16,6 C17.1045695,6 18,6.8954305 18,8 L18,12 L-4.01360012e-08,12 L-4.01360012e-08,8 C-4.01360013e-08,6.8954305 0.89543046,6 1.99999996,6 L16,6 Z M2.49999996,8 C1.67157284,8 0.99999996,8.44771525 0.99999996,9 C0.99999996,9.55228475 1.67157284,10 2.49999996,10 C3.32842708,10 3.99999996,9.55228475 3.99999996,9 C3.99999996,8.44771525 3.32842708,8 2.49999996,8 Z M15.5,8 C14.6715728,8 14,8.44771525 14,9 C14,9.55228475 14.6715728,10 15.5,10 C16.3284271,10 17,9.55228475 17,9 C17,8.44771525 16.3284271,8 15.5,8 Z"
        id="Combined-Shape"
      />
      <rect id="Rectangle" x="16" y="12" width="2" height="2" />
      <rect id="Rectangle" x="-4.01360012e-08" y="12" width="2" height="2" />
      <path
        d="M13.2802952,-3.06421555e-14 C13.7102146,-3.00549966e-14 14.0920198,0.274787096 14.2285385,0.682455277 L16.0092611,6 L14.7562922,5.999 L13.2793232,1.49589256 C13.2215962,1.31988619 13.0725473,1.19274109 12.8950517,1.16000589 L12.8042244,1.15171665 L5.33110395,1.15171665 C5.11456275,1.15171665 4.92263135,1.29110575 4.85563605,1.49702254 L4.85563605,1.49702254 L3.39029219,5.999 L2.01929219,6 L3.77336691,0.686521526 C3.90863187,0.276774172 4.2914654,-3.08959577e-14 4.72296221,-3.06421555e-14 L13.2802952,-3.06421555e-14 Z"
        id="Combined-Shape"
      />
    </g>
  </svg>
);
