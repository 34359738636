import React from 'react';

import { SVGIconComponent } from '../types';

export const applepay: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 166 106" {...props}>
    <title>{title}</title>

    <path
      d="M151.134 0H14.8661C14.2985 0 13.73 0 13.1635 0.00330109C12.6846 0.00670221 12.2068 0.012004 11.729 0.0250082C10.687 0.0531175 9.63608 0.114438 8.60711 0.298898C7.56169 0.48656 6.58889 0.792661 5.63985 1.27462C4.70686 1.74788 3.85269 2.36688 3.11247 3.10552C2.37192 3.84417 1.75133 4.69475 1.27696 5.62635C0.793666 6.57296 0.48658 7.54358 0.299741 8.58713C0.113814 9.61377 0.0519258 10.6618 0.0238448 11.7001C0.0110078 12.1766 0.00550187 12.6532 0.00238287 13.1296C-0.000926674 13.6959 0.000176509 14.2618 0.000176509 14.8291V91.172C0.000176509 91.7393 -0.000926674 92.3042 0.00238287 92.8716C0.00550187 93.348 0.0110078 93.8246 0.0238448 94.3012C0.0519258 95.3385 0.113814 96.3865 0.299741 97.4129C0.48658 98.4568 0.793666 99.427 1.27696 100.374C1.75133 101.305 2.37192 102.157 3.11247 102.895C3.85269 103.634 4.70686 104.253 5.63985 104.725C6.58889 105.209 7.56169 105.515 8.60711 105.702C9.63608 105.886 10.687 105.948 11.729 105.976C12.2068 105.987 12.6846 105.993 13.1635 105.996C13.73 106 14.2985 106 14.8661 106H151.134C151.701 106 152.269 106 152.836 105.996C153.313 105.993 153.791 105.987 154.271 105.976C155.311 105.948 156.362 105.886 157.393 105.702C158.437 105.515 159.41 105.209 160.359 104.725C161.293 104.253 162.145 103.634 162.887 102.895C163.626 102.157 164.247 101.305 164.722 100.374C165.207 99.427 165.513 98.4568 165.699 97.4129C165.885 96.3865 165.946 95.3385 165.974 94.3012C165.987 93.8246 165.993 93.348 165.996 92.8716C166 92.3042 166 91.7393 166 91.172V14.8291C166 14.2618 166 13.6959 165.996 13.1296C165.993 12.6532 165.987 12.1766 165.974 11.7001C165.946 10.6618 165.885 9.61377 165.699 8.58713C165.513 7.54358 165.207 6.57296 164.722 5.62635C164.247 4.69475 163.626 3.84417 162.887 3.10552C162.145 2.36688 161.293 1.74788 160.359 1.27462C159.41 0.792661 158.437 0.48656 157.393 0.298898C156.362 0.114438 155.311 0.0531175 154.271 0.0250082C153.791 0.012004 153.313 0.00670221 152.836 0.00330109C152.269 0 151.701 0 151.134 0Z"
      fill="black"
    />
    <path
      d="M151.134 3.5332L152.81 3.5364C153.264 3.53961 153.719 3.54451 154.175 3.55691C154.97 3.57832 155.899 3.62123 156.765 3.77608C157.518 3.91133 158.149 4.117 158.755 4.4247C159.353 4.7279 159.901 5.12523 160.38 5.60188C160.86 6.08174 161.259 6.62932 161.567 7.23262C161.874 7.83232 162.079 8.45912 162.214 9.21557C162.368 10.0701 162.411 10.9994 162.433 11.7965C162.445 12.2465 162.451 12.6964 162.454 13.1571C162.458 13.7142 162.458 14.2709 162.458 14.8291V91.172C162.458 91.7302 162.458 92.286 162.453 92.855C162.451 93.3049 162.445 93.7548 162.433 94.2055C162.411 95.0015 162.368 95.9303 162.212 96.7949C162.079 97.5409 161.874 98.1679 161.566 98.7706C161.258 99.3723 160.86 99.9193 160.382 100.396C159.901 100.876 159.354 101.272 158.749 101.578C158.148 101.885 157.517 102.09 156.772 102.224C155.888 102.381 154.92 102.424 154.191 102.444C153.732 102.454 153.276 102.46 152.808 102.463C152.251 102.467 151.691 102.467 151.134 102.467H14.866C14.8586 102.467 14.8514 102.467 14.8438 102.467C14.2929 102.467 13.7408 102.467 13.1797 102.463C12.7223 102.46 12.2659 102.454 11.8246 102.444C11.0789 102.424 10.1102 102.381 9.23376 102.225C8.4819 102.09 7.85157 101.885 7.24221 101.574C6.64259 101.271 6.09671 100.875 5.61522 100.394C5.13744 99.9186 4.74039 99.3733 4.43341 98.7707C4.12612 98.1686 3.92053 97.5397 3.78535 96.7848C3.62909 95.9219 3.58616 94.9968 3.56471 94.2063C3.55247 93.7536 3.54735 93.3011 3.54444 92.8511L3.54224 91.5228L3.54234 91.172V14.8291L3.54224 14.4784L3.54434 13.1529C3.54735 12.7002 3.55247 12.2477 3.56471 11.7954C3.58616 11.0041 3.62909 10.0786 3.78664 9.20847C3.92063 8.46033 4.12612 7.83152 4.43501 7.22642C4.73959 6.62832 5.13734 6.08234 5.61763 5.60338C6.09601 5.12603 6.64368 4.7292 7.24713 4.4231C7.84996 4.1169 8.48149 3.91133 9.23336 3.77638C10.0997 3.62113 11.0294 3.57832 11.8257 3.55681C12.2796 3.54451 12.7336 3.53961 13.1841 3.5365L14.866 3.5332H151.134"
      fill="white"
    />
    <path
      d="M45.317 35.6522C46.7383 33.8789 47.7028 31.498 47.4484 29.0649C45.3678 29.1681 42.8288 30.4341 41.3588 32.2087C40.0389 33.7284 38.8707 36.2091 39.1753 38.5401C41.5109 38.7422 43.8444 37.3757 45.317 35.6522Z"
      fill="black"
    />
    <path
      d="M47.4218 38.9952C44.0299 38.7937 41.1459 40.9153 39.5261 40.9153C37.9054 40.9153 35.425 39.0967 32.7422 39.1458C29.2503 39.1969 26.0103 41.1662 24.2384 44.2982C20.5937 50.5639 23.2765 59.8581 26.8207 64.9611C28.5419 67.4857 30.6162 70.2655 33.3493 70.1657C35.9317 70.0646 36.9439 68.4978 40.0829 68.4978C43.2196 68.4978 44.1314 70.1657 46.865 70.1152C49.6998 70.0646 51.4721 67.5893 53.1932 65.0622C55.1677 62.1842 55.976 59.4053 56.0268 59.2528C55.976 59.2023 50.5605 57.13 50.5103 50.9165C50.4591 45.7137 54.762 43.239 54.9646 43.0856C52.5347 39.5009 48.738 39.0967 47.4218 38.9952Z"
      fill="black"
    />
    <path
      d="M76.9559 31.9534C84.3281 31.9534 89.4617 37.0221 89.4617 44.4018C89.4617 51.8079 84.2225 56.903 76.771 56.903H68.6086V69.8504H62.7112V31.9534H76.9559V31.9534ZM68.6086 51.9655H75.3754C80.51 51.9655 83.4322 49.2082 83.4322 44.4282C83.4322 39.6487 80.51 36.9173 75.4018 36.9173H68.6086V51.9655V51.9655Z"
      fill="black"
    />
    <path
      d="M91.0024 61.9991C91.0024 57.1664 94.715 54.1989 101.298 53.8312L108.88 53.3849V51.2578C108.88 48.1849 106.8 46.3466 103.325 46.3466C100.033 46.3466 97.9795 47.922 97.4798 50.391H92.1086C92.4245 45.4008 96.6896 41.7241 103.536 41.7241C110.25 41.7241 114.541 45.2696 114.541 50.811V69.8515H109.091V65.308H108.96C107.354 68.3809 103.852 70.3241 100.218 70.3241C94.7942 70.3241 91.0024 66.9625 91.0024 61.9991ZM108.88 59.5043V57.3245L102.061 57.7444C98.6642 57.981 96.7424 59.4779 96.7424 61.8416C96.7424 64.2574 98.7434 65.8333 101.798 65.8333C105.773 65.8333 108.88 63.1019 108.88 59.5043Z"
      fill="black"
    />
    <path
      d="M119.687 80.0154V75.4193C120.107 75.5241 121.055 75.5241 121.529 75.5241C124.162 75.5241 125.584 74.4213 126.453 71.585C126.453 71.5323 126.954 69.9042 126.954 69.8779L116.948 42.2231H123.109L130.113 64.7043H130.218L137.223 42.2231H143.226L132.851 71.2958C130.482 77.9931 127.744 80.1466 122.004 80.1466C121.529 80.1466 120.107 80.0939 119.687 80.0154Z"
      fill="black"
    />
  </svg>
);
