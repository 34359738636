import React from 'react';

import { SVGIconComponent } from '../types';

export const list: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="M2 12.6667H4.22222V10.4444H2V12.6667ZM2 17.1111H4.22222V14.8889H2V17.1111ZM2 8.22222H4.22222V6H2V8.22222ZM6.44444 12.6667H22V10.4444H6.44444V12.6667ZM6.44444 17.1111H22V14.8889H6.44444V17.1111ZM6.44444 6V8.22222H22V6H6.44444ZM2 12.6667H4.22222V10.4444H2V12.6667ZM2 17.1111H4.22222V14.8889H2V17.1111ZM2 8.22222H4.22222V6H2V8.22222ZM6.44444 12.6667H22V10.4444H6.44444V12.6667ZM6.44444 17.1111H22V14.8889H6.44444V17.1111ZM6.44444 6V8.22222H22V6H6.44444Z" />
  </svg>
);
