import React from 'react';

import { SVGIconComponent } from '../types';

export const deleteCredit: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="M12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 Z M12,23 C18.0751322,23 23,18.0751322 23,12 C23,5.92486775 18.0751322,1 12,1 C5.92486775,1 1,5.92486775 1,12 C1,18.0751322 5.92486775,23 12,23 Z M5.64705882,11.2941176 L18.3529411,11.2941176 L18.3529411,12.7058824 L5.64705882,12.7058824 L5.64705882,11.2941176 Z" />{' '}
  </svg>
);
