import React from 'react';

import { SVGIconComponent } from '../types';

export const storeThree: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 29 28" {...props}>
    <title>{title}</title>
    <path d="M23.614 14.07H5.853c-.522 0-.784-.392-.653-.784l1.175-3.003h16.586l1.176 3.003c.26.392 0 .784-.523.784zM22.961 15.768h-1.567v4.179h-3.395v-4.18H16.43v4.18h-3.395v-4.18h-1.567v4.18H8.073v-4.18H6.506v6.4H22.96v-6.4zM19.304 4.667h-9.141c-.523 0-.915.391-.915.914V7.8c0 .522.392.914.915.914h9.141c.523 0 .915-.392.915-.914v-2.22c0-.523-.392-.914-.915-.914z" />
  </svg>
);
