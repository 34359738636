import React from 'react';

import { SVGIconComponent } from '../types';

export const sides: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 56 44" {...props}>
    <title>{title}</title>
    <path
      d="M2 19.503H54a2 2 0 0 1 1.93 2.527l-5.598 20.497A2 2 0 0 1 48.403 44H8.397a2 2 0 0 1-1.909-1.404L.09 22.099A2 2 0 0 1 2 19.503zM24.3 1.851A4.648 4.648 0 0 1 28.007 0c1.736 0 3.252.961 4.06 2.39A4.634 4.634 0 0 1 35.5.865c1.898 0 3.532 1.149 4.268 2.8a4.61 4.61 0 0 1 1.352-.201c2.295 0 4.205 1.68 4.605 3.897a4.6 4.6 0 0 1 1.951-.432c2.587 0 4.683 2.133 4.683 4.764 0 2.632-2.096 4.765-4.683 4.765-.093 0-.185-.003-.276-.008H8.614a4.676 4.676 0 0 1-.277.008c-2.586 0-4.683-2.133-4.683-4.765 0-2.63 2.097-4.764 4.683-4.764a4.6 4.6 0 0 1 1.951.432c.401-2.217 2.31-3.897 4.606-3.897a4.6 4.6 0 0 1 2.155.533C17.704 2.171 19.427.866 21.45.866c1.073 0 2.062.367 2.851.985z"
      fillRule="evenodd"
    />
  </svg>
);
